export type FunctionalClaim =
	| 'ViewRules'
	| 'CreateEditDeleteRules'
	| 'ViewNotifications'
	| 'ViewOrders'
	| 'ViewDisputes'
	| 'ViewTransports'
	| 'ViewRegions'
	| 'CreateEditDeleteRegions';
export const FunctionalClaims: FunctionalClaim[] = [
	'ViewRules',
	'CreateEditDeleteRules',
	'ViewNotifications',
	'ViewOrders',
	'ViewDisputes',
	'ViewTransports',
	'ViewRegions',
	'CreateEditDeleteRegions',
];
