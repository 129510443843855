import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
	ExtraTimeMarginRuleModel,
	IExtraTimeMarginRuleDto,
	IExtraTimeMarginRuleModel,
	IIso3166CountryCodeDto,
	IRegionSummary,
	RegionsQueryClient,
	RulesCommandClient,
	RulesQueryClient,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { IStringsWithFormat, useLocalizationContext } from '../../hooks/useLocalizationContext';
import { IOption } from '../../infrastructure/Models';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { setFieldTouched } from '../common/forms/FormsUtils';
import { NumberInputField } from '../common/forms/NumberInputField';
import { SelectDate } from '../common/forms/SelectDate';
import { SelectField } from '../common/forms/SelectField';
import { TextInputField } from '../common/forms/TextInputField';
import { showErrorToast } from '../common/toast/ToastNotification';
import { isValidTime } from './isValidTime';
import React from 'react';
import { LoadOrUnloadActionTypes } from '../../gen/LoadOrUnloadActionType';
import { executeApiCall } from 'infrastructure/executeApiCall';
import { useRecordContext } from 'context/records/useRecordContext';
import { Divider, Radio } from 'semantic-ui-react';

const createSchema = (strings: IStringsWithFormat) => {
	return yup.object<IExtraTimeMarginRuleModel>({
		countryCode: yup.string(),
		zipCode: yup.string(),
		timeMarginInMinutes: yup.number().required(strings.timeMarginIsRequired),
		timeRangeAfter: yup.string().required(strings.timeRangeAfterIsRequired),
		timeRangeBefore: yup.string().required(strings.timeRangeBeforeIsRequired),
		validityAfter: yup.date().required(strings.validityAfterIsRequired),
		validityBefore: yup.date().required(strings.validityBeforeIsRequired),
		loadOrUnloadAction: yup.string().required(strings.actionIsRequiredField),
		regionId: yup.string(),
	});
};

const parseInitialValues = (rule: IExtraTimeMarginRuleDto | undefined): IExtraTimeMarginRuleModel => {
	if (rule === undefined) {
		return {};
	} else {
		return { ...rule };
	}
};

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	rule?: IExtraTimeMarginRuleDto;
}

export const RuleForm = ({ cancel, confirm, rule }: IProps) => {
	const strings = useLocalizationContext();
	const queryClient = useClient(RulesQueryClient);
	const regionsQueryClient = useClient(RegionsQueryClient);
	const commandClient = useClient(RulesCommandClient);
	const [countries, setCountries] = useState<IIso3166CountryCodeDto[]>([]);
	const [regions, setRegions] = useState<IRegionSummary[]>([]);
	const schema = useMemo(() => createSchema(strings), [strings]);
	const isNew = rule === undefined;
	const initialValues = useMemo(() => parseInitialValues(rule), [rule]);
	const { loadOrUnloadActionTypeRecord } = useRecordContext();
	const [isUseRegion, setIsUseRegion] = useState<boolean>(rule?.hasRegion ?? false);

	useEffect(() => {
		const load = async () => {
			const r = await queryClient.allCountries();
			setCountries(r);
		};
		load();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const load = async () => {
			const r = await regionsQueryClient.all();
			setRegions(r);
		};
		load();
		// eslint-disable-next-line
	}, []);

	const onClickSubmit = async (props: FormikProps<IExtraTimeMarginRuleModel>, e: any) => {
		setFieldTouched<IExtraTimeMarginRuleModel>('countryCode', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('timeMarginInMinutes', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('timeRangeAfter', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('timeRangeBefore', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('validityAfter', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('validityBefore', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('zipCode', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('loadOrUnloadAction', props);
		setFieldTouched<IExtraTimeMarginRuleModel>('regionId', props);
		props.setSubmitting(true);
		e.persist();
		e.preventDefault();
		let isValid = await schema.isValid(props.values);
		props.setSubmitting(false);

		if (isValid) {
			if (isValidTime(props.values.timeRangeAfter!)) {
				isValid = false;
				props.setFieldError('timeRangeAfter', strings.timeFrameIncorrectFormat);
			}
			if (isValidTime(props.values.timeRangeBefore!)) {
				isValid = false;
				props.setFieldError('timeRangeBefore', strings.timeFrameIncorrectFormat);
			}
		}

		if (isValid) {
			handleSubmit(props.values, props);
		}
	};

	const handleSubmit = async (values: IExtraTimeMarginRuleModel, actions: FormikActions<IExtraTimeMarginRuleModel>) => {
		if (isUseRegion && (values.regionId === undefined || values.regionId === null)) {
			actions.setFieldError('regionId', `Selecteer een regio`);
			return;
		}
		if (isUseRegion === false && (values.zipCode === undefined || values.zipCode === null || values.zipCode === '')) {
			actions.setFieldError('zipCode', 'Vul een postcode in');
			return;
		}
		if (isUseRegion === false && (values.countryCode === undefined || values.countryCode === null || values.countryCode === '')) {
			actions.setFieldError('countryCode', 'Vul een landcode in');
			return;
		}

		actions.setSubmitting(false);
		if (isNew) {
			const r = await executeApiCall(commandClient.add(new ExtraTimeMarginRuleModel(values)), strings);
			if (r.hasError === false) {
				confirm();
			} else {
				showErrorToast(r.error!);
			}
		} else {
			const r = await executeApiCall(commandClient.update(rule!.id!, new ExtraTimeMarginRuleModel(values)), strings);
			if (r.hasError === false) {
				confirm();
			} else {
				showErrorToast(r.error!);
			}
		}
		actions.setSubmitting(false);
	};

	if (countries.length === 0) {
		return <div></div>;
	}

	return (
		<Formik<IExtraTimeMarginRuleModel>
			initialValues={initialValues}
			isInitialValid={false}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<div className='df-col pos-rel'>
						<div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>{isNew ? strings.addRule : strings.editRule}</div>
						<SelectDate<IExtraTimeMarginRuleModel>
							label={strings.validityFrom}
							xName='validityAfter'
						/>
						<SelectDate<IExtraTimeMarginRuleModel>
							label={strings.validityUntil}
							xName='validityBefore'
						/>
						<TextInputField<IExtraTimeMarginRuleModel>
							label={strings.timeFrameFrom}
							xName='timeRangeAfter'
							placeholder={`23:59`}
						/>
						<TextInputField<IExtraTimeMarginRuleModel>
							label={strings.timeFrameUntil}
							xName='timeRangeBefore'
							placeholder={`23:59`}
						/>
						<NumberInputField<IExtraTimeMarginRuleModel>
							xName='timeMarginInMinutes'
							label={strings.timeMarginInMinutes}
							placeholder={strings.timeMarginInMinutes}
							min={1}
						/>
						<Radio
							label={`Gebruik een regio`}
							name='radioGroup'
							checked={isUseRegion === true}
							onChange={() => setIsUseRegion(true)}
						/>
						<Radio
							label={`Gebruik een (wildcard) postcode`}
							name='radioGroup'
							checked={isUseRegion === false}
							onChange={() => setIsUseRegion(false)}
						/>
						<Divider
							fitted
							style={{ marginTop: 12, marginBottom: 12 }}
						/>
						{isUseRegion === false && (
							<>
								<TextInputField<IExtraTimeMarginRuleModel>
									label={strings.postalCode}
									xName='zipCode'
									placeholder={strings.zipCodeForExample}
								/>
								<SelectField<IExtraTimeMarginRuleModel>
									options={countries.map<IOption<string>>(t => ({ key: t.alpha2Code!, value: t.alpha2Code!, text: t.alpha2Code! }))}
									label={strings.country}
									xName='countryCode'
								/>
							</>
						)}
						{isUseRegion === true && (
							<SelectField<IExtraTimeMarginRuleModel>
								options={regions.map<IOption<string>>(t => ({ key: t.id!, value: t.id!, text: t.name! }))}
								label={strings.region}
								xName='regionId'
							/>
						)}
						<SelectField<IExtraTimeMarginRuleModel>
							options={LoadOrUnloadActionTypes.map<IOption<string>>(t => ({ key: t, value: t, text: loadOrUnloadActionTypeRecord[t] }))}
							label={strings.action}
							xName='loadOrUnloadAction'
						/>
						<div className='df-row-ac jc-e'>
							<div className='df-row-ac'>
								<TextLinkButton
									className='cancel-button'
									onClick={() => cancel()}
									style={{ marginRight: 4 }}>
									{strings.cancel}
								</TextLinkButton>
								<PrimaryButton
									disabled={props.isSubmitting}
									type='submit'
									onClick={e => onClickSubmit(props, e)}>
									{isNew ? strings.add : strings.change}
								</PrimaryButton>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
