import { IStringsWithFormat } from '../../hooks/useLocalizationContext';
import { LoadOrUnloadActionType } from '../../gen/LoadOrUnloadActionType';

export const createLoadOrUnloadActionTypeRecord = (strings: IStringsWithFormat): Record<LoadOrUnloadActionType, string> => {
	return {
		Load: strings.load,
		LoadOrUnload: `${strings.load}/${strings.unload}`,
		Unload: strings.unload,
	};
};
