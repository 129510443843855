import React from 'react';
import { Table } from 'semantic-ui-react';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { useTablePage } from '../../../hooks/useTableSortAndPage';
import { BaseTableSort } from './BaseTableSort';
import { IHeader, IQueryResult, ISortAndPageProps, ISortProps } from '../../../infrastructure/Models';
import './BaseTable.scss';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';

interface IProps<T, TQueryParams extends ISortAndPageProps> {
    canSort: boolean;
    headers: IHeader<T>[];
    uniqueIdentifier: keyof T;
    queryResult: IQueryResult<T> | undefined;
    params: TQueryParams;
    onSortOrPage: (params: TQueryParams) => void;
    fallbackSortProps?: ISortProps;
    className?: string;
    singleIdentifier: string;
    onClickRow?: (inst: T) => void;
    formatRow?: (inst: T) => string;
}

export function BaseTableSortAndPage<T, TQueryParams extends ISortAndPageProps>({ queryResult, onSortOrPage, params, singleIdentifier, onClickRow, formatRow, ...rest }: IProps<T, TQueryParams>) {
    const locContext = useLocalizationContext();
    const [next, previous] = useTablePage<TQueryParams>();
    if (!queryResult || !queryResult.values) {
        return null;
    }

    function renderFooter(qr: IQueryResult<T>) {
        return (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={rest.headers.length}>
                        <div className="df-row-ac jc-sb bt-footer">
                            <div className="bt-footer-page-info">
                                {locContext.formatString('pagingTable', singleIdentifier, qr.currentFrom, qr.currentTo, qr.totals)}
                            </div>
                            <div className="df-row-ac">
                                <PrimaryButton small outline className="bt-previous-button" disabled={!qr.hasPreviousPage} onClick={() => onSortOrPage(previous(params))}>{locContext.previous}</PrimaryButton>
                                <PrimaryButton small outline disabled={!qr.hasNextPage} onClick={() => onSortOrPage(next(params))}>{locContext.next}</PrimaryButton>
                            </div>
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    }

    return (
        <BaseTableSort<T, TQueryParams>
            {...rest}
            params={params}
            values={queryResult.values}
            onSort={(t) => onSortOrPage(t)}
            footer={renderFooter(queryResult)}
            onClickRow={onClickRow}
            formatRow={formatRow} />
    );
}