import { BaseInformation } from 'components/common/modal/BaseInformation';
import { IRegionDto } from 'gen/ApiClients';
import React from 'react';

interface IProps {
	item: IRegionDto;
}

export const RegionPostalCodeDetailsDialog = ({ item }: IProps) => {
	React.useEffect(() => {
		console.log(item);
	}, [item]);

	return (
		<BaseInformation
			description={
				<div className='df-col'>
					{item.postalCodes!.map(t => (
						<div key={t.postalCode!.toString()}>{`- ${t.postalCode}: ${t.placeName}`}</div>
					))}
				</div>
			}
			title={`Postcodes voor ${item.name}`}
		/>
	);
};
