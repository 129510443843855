import React, { useContext, useState, useEffect } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterDataContext } from '../../context/FilterDataContext';
import { toOptions, clearNullOrUndefinedValues } from '../../infrastructure/Utils';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import { INotificationModel, NotificationModel } from '../../gen/ApiClients';
import { SelectField } from '../common/forms/SelectField';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { ToggleCheckbox } from '../common/ToggleCheckbox';
import { TextInputField } from '../common/forms/TextInputField';
import { IOption } from '../../infrastructure/Models';
import { SelectDate } from '../common/forms/SelectDate';
import { setFieldTouched } from '../common/forms/FormsUtils';
import { TextareaField } from '../common/forms/TextareaField';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import * as yup from 'yup';
import './NotificationForm.scss';

interface IModel extends INotificationModel {
	targetAudience?: 'Servicepartners' | 'Klanten';
	priority?: 'Hoog' | 'Normaal';
}

interface IProps {
	onCancel: () => void;
	onSubmit: (model: INotificationModel) => void;
	notification?: INotificationModel | undefined;
}
export const NotificationForm = ({ onCancel, onSubmit, notification }: IProps) => {
	const locContext = useLocalizationContext();
	const filterDataContext = useContext(FilterDataContext);
	const options = toOptions(filterDataContext.allServicePartners);
	const [isForSpecificServicePartner, setIsForSpecificServicePartner] = useState<boolean>(false);
	const [isUseAfterDate, setIsUseAfterDate] = useState<boolean>(false);
	const [isUseBeforeDate, setIsUseBeforeDate] = useState<boolean>(false);
	const isNew = notification === undefined ? false : true;
	const [initValues, setInitValues] = useState<IModel>(
		clearNullOrUndefinedValues<IModel>(
			{
				...notification,
				targetAudience: notification ? (notification.isForClients ? 'Klanten' : 'Servicepartners') : undefined,
				priority: notification ? (notification.isHighPriority ? 'Hoog' : 'Normaal') : undefined,
			},
			NotificationModel
		)
	);

	const validateSchema = yup.object<IModel>().shape<IModel>({
		targetAudience: yup.mixed().required(locContext.validateTargetAudience),
		priority: yup.mixed().required(locContext.fillIn),
		title: yup.string().required(locContext.fillIn),
		description: yup.string().required(locContext.fillIn),
	});

	useEffect(() => {
		if (notification) {
			setInitValues({ ...notification, targetAudience: notification.isForClients ? 'Klanten' : 'Servicepartners' });
			setIsUseAfterDate(notification.showAfterDate === undefined ? false : true);
			setIsUseBeforeDate(notification.showBeforeDate === undefined ? false : true);
		}
	}, [notification]);

	const onClickSubmit = async (props: FormikProps<IModel>, e: any) => {
		e.persist();
		const isValid = await validateSchema.isValid(props.values);
		props.setSubmitting(false);
		if (!isValid) {
			e.preventDefault();
		}
		setFieldTouched<IModel>('targetAudience', props);
		setFieldTouched<IModel>('priority', props);
		setFieldTouched<IModel>('title', props);
		setFieldTouched<IModel>('description', props);
	};

	const handleSubmit = async (values: IModel, actions: FormikActions<IModel>) => {
		actions.setSubmitting(false);
		onSubmit({
			...values,
			showAfterDate: isUseAfterDate ? values.showAfterDate : undefined,
			showBeforeDate: isUseBeforeDate ? values.showBeforeDate : undefined,
			isForClients: values.targetAudience === 'Klanten' ? true : false,
			specificServicePartnerId: values.targetAudience === 'Servicepartners' ? values.specificServicePartnerId : undefined,
			isHighPriority: values.priority === 'Hoog' ? true : false,
		});
	};

	return (
		<div>
			<Formik<IModel>
				initialValues={initValues}
				isInitialValid={true}
				validationSchema={validateSchema}
				onSubmit={handleSubmit}>
				{props => (
					<Form>
						<div className='df-col not-form-container pos-rel'>
							<div className='not-title'>{locContext.notificationFormTitle}</div>
							<TextInputField<IModel>
								label={locContext.title}
								xName='title'
								placeholder={locContext.title}
							/>
							<SelectField<IModel>
								options={['Hoog', 'Normaal'].map<IOption<string>>(t => ({ key: t, value: t, text: t }))}
								label={locContext.priority}
								xName='priority'
							/>
							<SelectField<IModel>
								options={['Servicepartners', 'Klanten'].map<IOption<string>>(t => ({ key: t, value: t, text: t }))}
								label={locContext.targetAudience}
								xName='targetAudience'
							/>
							{props.values.targetAudience === 'Servicepartners' ? (
								<div className='df-col'>
									<div className='df-row-ac jc-sb option'>
										<div className='option-text'>{locContext.forSpecificServicePartner}?</div>
										<ToggleCheckbox externalProps={{ value: isForSpecificServicePartner, setValue: setIsForSpecificServicePartner }} />
									</div>
									{isForSpecificServicePartner ? (
										<SelectField<IModel>
											options={options}
											label={locContext.servicePartner}
											xName='specificServicePartnerId'
										/>
									) : null}
								</div>
							) : null}
							<div className='df-row-ac jc-sb option'>
								<div className='option-text'>{locContext.showAfterDate}?</div>
								<ToggleCheckbox externalProps={{ value: isUseAfterDate, setValue: setIsUseAfterDate }} />
							</div>
							{isUseAfterDate ? (
								<SelectDate<IModel>
									label={locContext.showAfterDate}
									xName='showAfterDate'
								/>
							) : null}
							<div className='df-row-ac jc-sb option'>
								<div className='option-text'>{locContext.showBeforeDate}?</div>
								<ToggleCheckbox externalProps={{ value: isUseBeforeDate, setValue: setIsUseBeforeDate }} />
							</div>
							{isUseBeforeDate ? (
								<SelectDate<IModel>
									label={locContext.showBeforeDate}
									xName='showBeforeDate'
								/>
							) : null}
							<TextareaField<IModel>
								label={locContext.description}
								xName='description'
							/>
							<div className='df-row-ac jc-e'>
								<div className='df-row-ac'>
									<TextLinkButton
										className='cancel-button'
										onClick={() => onCancel()}>
										{locContext.cancel}
									</TextLinkButton>
									<PrimaryButton
										disabled={props.isSubmitting}
										type='submit'
										onClick={e => onClickSubmit(props, e)}>
										{isNew ? locContext.change : locContext.add}
									</PrimaryButton>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
