import { LoadOrUnloadActionType } from 'gen/LoadOrUnloadActionType';
import { FunctionalClaim } from 'gen/FunctionalClaim';
import { UserRole } from 'gen/UserRole';
import React from 'react';
import { RuleLogEntryType } from 'gen/RuleLogEntryType';

interface IRecordContext {
	userRoleRecord: Record<UserRole, string>;
	functionalClaimRecord: Record<FunctionalClaim, string>;
	loadOrUnloadActionTypeRecord: Record<LoadOrUnloadActionType, string>;
	ruleLogEntryTypeRecord: Record<RuleLogEntryType, string>;
}

export const RecordContext = React.createContext<IRecordContext>(undefined as any);
