import { ITransportSummary } from './gen/ApiClients';

export const LoginRoute = '/login';
export const ConfirmUserRoute = '/confirm-user/:token';
export const ForgotPasswordRoute = '/forgot-password';
export const ResetPasswordRoute = '/reset-password/:token';

export const SettingsRoute = '/settings';
export const NotificationsRoute = '/notifications';
export const OrdersRoute = '/orders';
export const DisputesRoute = '/disputes';
export const TransportsRoute = '/transports';
export const TransportDetailRoute = '/transports/:fileNumber';
export const RulesRoute = '/rules';
export const RegionsRoute = '/regions';
export const RuleLogsRoute = '/rule-logs';
export const UsersRoute = '/users';

export const DashboardRoute = '/dashboard';

export const calculateTransportDetailRoute = (inst: ITransportSummary) => TransportsRoute + '/' + inst.fileNumber;
