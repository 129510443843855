import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import './TextLinkButton.scss';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    underline?: boolean;
    gray?: boolean;
    small?: boolean;
    extraSmall?: boolean;
}

export const TextLinkButton = ({ children, underline = false, gray = false, small = false, extraSmall = false, ...rest }: IProps) => {
    const className = useCombineClassNames(`text-link-button${gray ? ' tlb-gray' : ''}${underline ? ' tlb-underline' : ''}${small ? ' tlb-small' : ''}${extraSmall ? ' tlb-extra-small' : ''}`, rest);
    return (
        // type="button" before {...rest} -> so can override this behaviour
        <button type="button" {...rest} className={className}>{children}</button>
    );
}