import { isNumber } from 'lodash';

export const isValidTime = (time: string): boolean => {
	if (time === undefined || time === '' || time === null) {
		return false;
	}
	const indexOfX = time.indexOf(':');
	if (indexOfX === -1) {
		return false;
	}
	const splits = time.split(':');
	if (splits.length !== 2) {
		return false;
	}
	if (isNumber(splits[0]) === false) {
		return false;
	}
	const hour = parseInt(splits[0]);
	if (hour < 0 || hour > 23) {
		return false;
	}
	if (isNumber(splits[1]) === false) {
		return false;
	}
	const minutes = parseInt(splits[1]);
	if (minutes < 0 || minutes > 59) {
		return false;
	}
	return true;
};
