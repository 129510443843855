import { IUsersQueryParams } from 'gen/ApiClients';
import React, { useState } from 'react';
import { DefaultUsersQueryParams } from './DefaultUsersQueryParams';
import { UsersQueryContext } from './UsersQueryContext';

export const UsersQueryProvider = ({ children }: any) => {
	const [params, setParams] = useState<IUsersQueryParams>(DefaultUsersQueryParams);

	return (
		<UsersQueryContext.Provider
			value={{
				params: params,
				setParams: setParams,
			}}>
			{children}
		</UsersQueryContext.Provider>
	);
};
