import { IStrings } from './IStrings';

export const nl: IStrings = {
    action: `Actie`,
    actionIsRequiredField: `Actie is een verplicht veld`,
    activated: `Geactiveerd`,
    activateRule: `Activeer regel`,
    activateRuleConfirmation: `Wil je deze regel activeren?`,
    add: `Voeg toe`,
    added: `Toegevoegd`,
    addNotification: `Nieuwe melding`,
    addRegion: `Voeg regio toe`,
    addRule: `Regel toevoegen`,
    admin: `Beheerder`,
    adminLoginWelcomeTitle: `Welkom Euro-Sprinters!`,
    after: `Na`,
    all: `Alles`,
    allClients: `Alle klanten`,
    allServicePartners: `Alle servicepartners`,
    always: `Altijd`,
    at: `Om`,
    autoGenerateFormTitle: `CMR/Groene kaart meldingen`,
    autoGenerateNotifications: `Auto-generate`,
    backToOverview: `Terug naar overzicht`,
    before: `Voor`,
    blockedQuestionMark: `Geblokkeerd?`,
    blockUser: `Gebruiker blokkeren`,
    blockUserConfirmation: `Wil je deze gebruiker blokkeren? \nHierna kan deze gebruiker niet meer aanmelden.`,
    cancel: `Annuleer`,
    change: `Aanpassen`,
    changed: `Gewijzigd`,
    changeUserRights: `Wijzig rechten gebruiker`,
    changeUserRole: `Wijzig rol gebruiker`,
    client: `Klant`,
    cmrExpiredAfterDate: `Vervaldatum CMR`,
    confirmDeleteNotification: `Ben je zeker dat je deze melding wil verwijderen?`,
    confirmDeleteRule: `Wil je deze regel definitief verwijderen?`,
    confirmDeleteUser: `Wil je echt deze gebruiker verwijderen?`,
    confirmUserSubTitle: `Kies een wachtwoord om je profiel te vervolledigen`,
    confirmUserTitle: `Vervolledig je profiel`,
    contact: `Contact`,
    costAmount: `Kost`,
    country: `Land`,
    countryIsRequired: `Land is een verplicht veld`,
    createdDate: `Datum aangemaakt`,
    createEditDeleteRegions: `Regio's editeren, bewerken en verwijderen`,
    createEditDeleteRules: `Regels editeren, bewerken en verwijderen`,
    date: `Datum`,
    deactivated: `Gedeactiveerd`,
    deactivateRule: `Deactiveer regel`,
    deactiveRuleConfirmation: `Wil je deze regel deactiveren?`,
    deleted: `Verwijderd`,
    deleteFilter: `Verwijder filter`,
    deleteNotification: `Melding verwijderen`,
    deleteRuleQuestion: `Regel verwijderen?`,
    deleteUserQuestion: `Gebruiker verwijderen`,
    department: `Afdeling`,
    description: `Beschrijving`,
    discounts: `Kortingen`,
    disputes: `Betwistingen`,
    disputeSingular: `Betwisting`,
    downloadTrackAndTrace: `Download Track & Trace`,
    driver: `Chauffeur`,
    driverMobilePhone: `GSM nummer chauffeur`,
    editRule: `Regel editeren`,
    email: `E-mailadres`,
    error401: `Je bent niet aangemeld, gelieve eerst aan te melden.`,
    error403: `Je bent niet geauthoriseerd om deze actie uit te voeren.`,
    error500: `Ongekende fout op de server. Probeer opnieuw.`,
    errorNoNetwork: `Geen verbinding met de server, controleer je internet verbinding`,
    extras: `Extras`,
    extraStop: `Extra stop`,
    extraTimeMarginRules: `Regels extra tijdsmarge`,
    fileDate: `Datum dossier`,
    fileNumber: `Dossiernummer`,
    fillIn: `Vul in`,
    fillInPassword: `Vul wachtwoord in`,
    filterOn: `Filter op`,
    firstName: `Voornaam`,
    firstStop: `Eerste stop`,
    firstStopDate: `Datum eerste stop`,
    forClients: `Voor klanten`,
    forCmr: `Voor CMR`,
    forgotPassword: `Wachtwoord vergeten?`,
    forgotPasswordSubtitle: `Vul je e-mailadres in en ontvang een link om je wachtwoord te resetten`,
    forgotPasswordTitle: `Wachtwoord vergeten`,
    forGreenCard: `Voor Groene kaart`,
    forServicePartners: `Voor servicepartners`,
    forSpecificServicePartner: `Voor specifieke servicepartner`,
    generate: `Genereer`,
    greenCardExpiredAfterDate: `Vervaldatum Groene kaart`,
    hasConfirmedQuestionMark: `Bevestigd?`,
    internalReference: `Interne referentie`,
    invalidCombinationUsernamePassword: `Ongeldige combinatie gebruikersnaam en wachtwoord`,
    isActive: `Is actief?`,
    isRead: `Gelezen?`,
    lastMonth: `Vorige maand`,
    lastName: `Achternaam`,
    lastStop: `Laatste stop`,
    lastWeek: `Vorige week`,
    lastYear: `Vorig jaar`,
    load: `Laden`,
    loadAndUnloadAddressPlusStops: `Laad- en losadres (+stops)`,
    login: `Aanmelden`,
    loginWelcomeSubtitle: `Meld je aan met je gebruikersnaam en wachtwoord.`,
    logOut: `Uitloggen`,
    logs: `Logs`,
    markAsRead: `Markeer als gelezen`,
    markAsUnread: `Markeer als ongelezen`,
    markedAsRead: `Gemarkeerd als gelezen`,
    markedAsUnread: `Gemarkeerd als ongelezen`,
    message: `Bericht`,
    newRule: `Nieuwe regel`,
    next: `Volgende`,
    no: `Nee`,
    none: `Niets`,
    noOneHasReadThis: `Niemand heeft dit reeds gelezen`,
    noRightsGranted: `Geen rechten toegekend`,
    notification: `Melding`,
    notificationAdded: `Melding aangemaakt`,
    notificationChanged: `Melding gewijzigd`,
    notificationDeleted: `Melding verwijderd`,
    notificationFormTitle: `Melding wijzigen/toevoegen`,
    notifications: `Meldingen`,
    oldRule: `Oude regel`,
    optional: `Optioneel`,
    order: `Bestelling`,
    orders: `Bestellingen`,
    pagingTable: `{0} {1} tot {2} van {3}`,
    password: `Wachtwoord`,
    passwordsDoNotMatch: `Wachtwoorden komen niet overeen`,
    postalCode: `Postcode`,
    previous: `Vorige`,
    price: `Prijs`,
    priority: `Prioriteit`,
    read: `Gelezen`,
    readHistory: `Leeshistoriek`,
    reference: `Referentie`,
    region: `Regio`,
    regions: `Regio's`,
    registerNewUser: `Registreer nieuwe gebruiker`,
    regularUser: `Reguliere gebruiker`,
    repeatPassword: `Herhaal wachtwoord`,
    resendRegistrationLink: `Link opnieuw verzenden`,
    resendRegistrationLinkConfirmation: `Wil je opnieuw een link sturen waarmee \ndeze gebruiker zijn wachtwoord kan kiezen?`,
    resetPassword: `Stel wachtwoord opnieuw in`,
    resetPasswordEmailSent: `Mail verstuurd met instructies om wachtwoord opnieuw in te stellen, check mailbox`,
    resetPasswordSubTitle: `Vul je e-mailadres in en kies een nieuw wachtwoord`,
    resetPasswordTitle: `Wachtwoord opnieuw instellen`,
    rights: `Rechten`,
    role: `Rol`,
    route: `Route`,
    ruleDeleted: `Regel verwijderd`,
    search: `Zoek`,
    searchOn: `Zoek op`,
    searchOnReference: `Zoek op referentie`,
    searchOnTitleOrDescription: `Zoek op titel of beschrijving`,
    select: `Selecteer`,
    serverError: `Een ongekende fout is opgetreden op de server.\nGelieve het opnieuw te proberen.\nAls de fout blijft aanhouden, gelieve Euro-Sprinters te contacteren.`,
    serviceAmount: `Servicebedrag`,
    servicePartner: `Servicepartner`,
    setPassword: `Stel wachtwoord in`,
    settings: `Instellingen`,
    showAfterDate: `Toon na datum`,
    showBeforeDate: `Toon voor datum`,
    showOnDate: `Toon op datum`,
    size: `Maat`,
    sizePolo: `Maat Polo`,
    sizeSweatShirt: `Maat Hoodie`,
    sizeTShirt: `Maat T-Shirt`,
    startersPackage: `Starterspakket`,
    startersPackages: `Starterspakketten`,
    status: `Status`,
    subDepartment: `Subafdeling`,
    successfullyLoggedOut: `Succesvol uitgelogd!`,
    superUser: `Super gebruiker`,
    targetAudience: `Doelgroep`,
    thisMonth: `Deze maand`,
    thisWeek: `Deze week`,
    thisYear: `Dit jaar`,
    timeFrame: `Tijdskader`,
    timeFrameAfter: `Tijdskader na`,
    timeFrameBefore: `Tijdskader voor`,
    timeFrameFrom: `Tijdskader vanaf`,
    timeFrameIncorrectFormat: `Incorrect formaat, verwacht in de vorm van HH:mm (bvb. 23:59)`,
    timeFrameUntil: `Tijdskader tot`,
    timeMarginInMinutes: `Tijdsmarge (in minuten)`,
    timeMarginIsRequired: `Tijdsmarge is een verplicht veld`,
    timeRangeAfterIsRequired: `Tijdskader voor is een verplicht veld`,
    timeRangeBeforeIsRequired: `Tijdskader na is een verplicht veld`,
    title: `Titel`,
    today: `Vandaag`,
    transport: `Transport`,
    transports: `Transporten`,
    unblockUser: `Gebruiker deblokkeren`,
    unblockUserConfirmation: `Wil je deze gebruiker deblokkeren? \nHierna kan deze gebruiker terug aanmelden.`,
    unload: `Lossen`,
    unread: `Niet gelezen`,
    user: `Gebruiker`,
    userDeleted: `Gebruiker verwijderd`,
    userName: `Gebruikersnaam`,
    users: `Gebruikers`,
    validatePassword: `Vul jouw wachtwoord in`,
    validateTargetAudience: `Selecteer een doelgroep`,
    validateUsername: `Vul jouw gebruikersnaam in`,
    validityAfter: `Geldigheid na`,
    validityAfterIsRequired: `Geldigheid na is een verplicht veld`,
    validityBefore: `Geldigheid voor`,
    validityBeforeIsRequired: `Geldigheid voor is een verplicht veld`,
    validityFrom: `Geldigheid vanaf`,
    validityUntil: `Geldigheid tot`,
    viewDisputes: `Bekijk betwistingen`,
    viewNotifications: `Bekijk meldingen`,
    viewOrders: `Bekijk bestellingen`,
    viewRegions: `Bekijk regio's`,
    viewRules: `Regels bekijken`,
    viewTransports: `Bekijk transporten`,
    yes: `Ja`,
    yesCommaActivate: `Ja, activeer!`,
    yesCommaBlock: `Ja, blokkeer`,
    yesCommaDeactivate: `Ja, deactiveer`,
    yesCommaUnblock: `Ja, deblokkeer`,
    yesDelete: `Ja, verwijder`,
    yesterday: `Gisteren`,
    zipCodeForExample: `Bvb. * of 2* of 2000`,
    zipCodeIsRequired: `Postcode is een verplicht veld`,
}
