import { FunctionalClaim } from 'gen/FunctionalClaim';
import { IStrings } from 'localization/IStrings';

export const createFunctionalClaimRecord = (strings: IStrings): Record<FunctionalClaim, string> => {
	return {
		CreateEditDeleteRules: strings.createEditDeleteRules,
		ViewRules: strings.viewRules,
		ViewDisputes: strings.viewDisputes,
		ViewNotifications: strings.viewNotifications,
		ViewOrders: strings.viewOrders,
		ViewTransports: strings.viewTransports,
		CreateEditDeleteRegions: strings.createEditDeleteRegions,
		ViewRegions: strings.viewRegions,
	};
};
