import { PrimaryButton } from 'components/common/buttons/PrimaryButton';
import { FormDialog } from 'components/common/forms/FormDialog';
import { setFieldError } from 'components/common/forms/FormsUtils';
import { PasswordField } from 'components/common/forms/PasswordField';
import { TextInputField } from 'components/common/forms/TextInputField';
import { AuthContext } from 'context/AuthContext';
import { useRouterContext } from 'context/useRouterContext';
import { Form, Formik, FormikActions } from 'formik';
import { AuthClient, IResetPasswordRequest, ResetPasswordRequest } from 'gen/ApiClients';
import { useClient } from 'hooks/useClient';
import { useLocalizationContext } from 'hooks/useLocalizationContext';
import { IStrings } from 'localization/IStrings';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NotificationsRoute } from 'routes';
import * as yup from 'yup';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';

interface IModel {
	email: string;
	password: string;
	confirmPassword: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		email: yup.string().email().required(),
		password: yup.string().required(strings.fillInPassword),
		confirmPassword: yup
			.string()
			.required(strings.repeatPassword)
			.oneOf([yup.ref('password')], strings.passwordsDoNotMatch),
	});
};

export const ResetPasswordPage = () => {
	const authContext = useContext(AuthContext);
	const routerContext = useRouterContext();
	const client = useClient<AuthClient>(AuthClient);
	const strings = useLocalizationContext();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const [token, setToken] = useState<string>();

	useEffect(() => {
		authContext.logout();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (routerContext?.location !== undefined) {
			setToken((routerContext.location.pathname as string).replace('/reset-password/', ''));
		}
		// eslint-disable-next-line
	}, [routerContext.location]);

	const handleSubmit = async (values: IModel, actions: FormikActions<IModel>) => {
		const request: IResetPasswordRequest = {
			email: values.email,
			password: values.password,
			resetPasswordTokenId: token,
		};

		const r = await client.resetPassword(new ResetPasswordRequest(request));

		if (!r.hasError && r.token) {
			authContext.login({ token: r.token!, user: r.user! });
			routerContext.history.push(NotificationsRoute);
		} else if (r.errorEmail) {
			setFieldError<IResetPasswordRequest>('email', r.errorEmail, actions);
		}
		actions.setSubmitting(false);
	};

	return (
		<EmptyPageWithLogo
			title={strings.resetPasswordTitle}
			subTitle={strings.resetPasswordSubTitle}>
			<FormDialog style={{ width: 600 }}>
				<Formik<IModel>
					initialValues={{ email: '', password: '', confirmPassword: '' }}
					validationSchema={schema}
					onSubmit={handleSubmit}>
					{props => (
						<Form
							autoComplete='off'
							className='df-col'
							style={{ minWidth: '400px' }}>
							<TextInputField<IModel>
								xName='email'
								label={strings.email}
							/>
							<PasswordField<IModel>
								xName='password'
								label={strings.password}
							/>
							<PasswordField<IModel>
								xName='confirmPassword'
								label={strings.repeatPassword}
							/>
							<PrimaryButton
								style={{ marginTop: '20px' }}
								type='submit'
								disabled={props.isSubmitting}>
								{strings.resetPassword}
							</PrimaryButton>
						</Form>
					)}
				</Formik>
			</FormDialog>
		</EmptyPageWithLogo>
	);
};
