import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';

interface IProps {
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
}

const fallback: IProps = {
    isLoading: false,
    setLoading: empty,
}

export const WrapperFullScreenLoadContext = React.createContext<IProps>(fallback);

export const WrapperFullScreenLoadProvider = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let timer: NodeJS.Timeout;
    const setIsLoadingWithTimer = (isLoading: boolean) => {
        if (isLoading) {
            timer = setTimeout(() => {
                setIsLoading(true);
            }, 1000);
        } else {
            clearTimeout(timer);
            setIsLoading(false);
        }
    }

    return (
        <WrapperFullScreenLoadContext.Provider
            value={{
                isLoading: isLoading,
                setLoading: (isLoading) => setIsLoadingWithTimer(isLoading),
            }}>
            {children}
        </WrapperFullScreenLoadContext.Provider>
    );
}
