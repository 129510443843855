import { IRulesQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { BooleanFilterItem } from '../common/filter/BooleanFilterItem';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { FilterBar } from '../common/filter/FilterBar';
import React from 'react';

function isFiltered(pars: IRulesQueryParams): boolean {
	if (pars === undefined) {
		return false;
	} else if (pars.isActive !== undefined) {
		return true;
	} else if (pars.validityAfterAfterDate !== undefined) {
		return true;
	} else if (pars.validityAfterBeforeDate !== undefined) {
		return true;
	} else if (pars.validityBeforeAfterDate !== undefined) {
		return true;
	} else if (pars.validityBeforeBeforeDate !== undefined) {
		return true;
	} else {
		return false;
	}
}

interface IProps {
	params: IRulesQueryParams;
	onFilter: (params: IRulesQueryParams) => void;
}

export const FilterRules = ({ params, onFilter }: IProps) => {
	const locContext = useLocalizationContext();

	const handleClear = () => {
		onFilter({
			...params,
			isActive: undefined,
			validityAfterAfterDate: undefined,
			validityAfterBeforeDate: undefined,
			validityBeforeAfterDate: undefined,
			validityBeforeBeforeDate: undefined,
		});
	};

	return (
		<FilterBar
			onClear={() => handleClear()}
			isFiltered={isFiltered(params)}>
			<div className='df-row-ac'>
				<BooleanFilterItem
					icon={['fas', 'star']}
					label={locContext.isActive}
					value={params.isActive}
					trueText={locContext.yes}
					falseText={locContext.no}
					onFilter={val => onFilter({ ...params, isActive: val })}
				/>
				<DateFilterItem
					undefinedLabel={locContext.validityAfter}
					afterDate={params.validityAfterAfterDate}
					beforeDate={params.validityAfterBeforeDate}
					onFilter={val => onFilter({ ...params, validityAfterAfterDate: val.after, validityAfterBeforeDate: val.before })}
				/>
				<DateFilterItem
					undefinedLabel={locContext.validityBefore}
					afterDate={params.validityBeforeAfterDate}
					beforeDate={params.validityBeforeBeforeDate}
					onFilter={val => onFilter({ ...params, validityBeforeAfterDate: val.after, validityBeforeBeforeDate: val.before })}
				/>
			</div>
		</FilterBar>
	);
};
