import { useRecordContext } from 'context/records/useRecordContext';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { UserRole, UserRoles } from 'gen/UserRole';
import { executeApiCall } from 'infrastructure/executeApiCall';
import { IStrings } from 'localization/IStrings';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { IRegisterUserRequest, RegisterUserRequest, UsersCommandClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { IOption } from '../../infrastructure/Models';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { setFieldTouched } from '../common/forms/FormsUtils';
import { SelectField } from '../common/forms/SelectField';
import { TextInputField } from '../common/forms/TextInputField';
import { showErrorToast } from '../common/toast/ToastNotification';

const createSchema = (strings: IStrings) => {
	return yup.object<IRegisterUserRequest>({
		email: yup.string().email().required(),
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		role: yup.string().required(),
	});
};

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const RegisterUserForm = ({ cancel, confirm }: IProps) => {
	const strings = useLocalizationContext();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const commandClient = useClient(UsersCommandClient);
	const { userRoleRecord } = useRecordContext();

	const onClickSubmit = async (props: FormikProps<IRegisterUserRequest>, e: any) => {
		setFieldTouched<IRegisterUserRequest>('email', props);
		setFieldTouched<IRegisterUserRequest>('firstName', props);
		setFieldTouched<IRegisterUserRequest>('lastName', props);
		setFieldTouched<IRegisterUserRequest>('role', props);
		props.setSubmitting(true);
		e.persist();
		e.preventDefault();
		let isValid = await schema.isValid(props.values);
		props.setSubmitting(false);

		if (isValid) {
			handleSubmit(props.values, props);
		}
	};

	const handleSubmit = async (values: IRegisterUserRequest, actions: FormikActions<IRegisterUserRequest>) => {
		actions.setSubmitting(true);
		const r = await executeApiCall(commandClient.register(new RegisterUserRequest(values)), strings);
		if (r.hasError === false) {
			confirm();
		} else {
			showErrorToast(r.error!);
		}
		actions.setSubmitting(false);
	};

	return (
		<Formik<IRegisterUserRequest>
			initialValues={{ email: '', firstName: '', lastName: '', role: '' }}
			isInitialValid={false}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<div className='df-col not-form-container pos-rel'>
						<div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>{strings.registerNewUser}</div>
						<TextInputField<IRegisterUserRequest>
							label={strings.email}
							xName='email'
						/>
						<TextInputField<IRegisterUserRequest>
							label={strings.firstName}
							xName='firstName'
						/>
						<TextInputField<IRegisterUserRequest>
							label={strings.lastName}
							xName='lastName'
						/>
						<SelectField<IRegisterUserRequest>
							options={UserRoles.map<IOption<string>>(t => ({ key: t, value: t, text: userRoleRecord[t as UserRole] }))}
							label={strings.role}
							xName='role'
						/>
						<div className='df-row-ac jc-e'>
							<div className='df-row-ac'>
								<TextLinkButton
									className='cancel-button'
									onClick={() => cancel()}>
									{strings.cancel}
								</TextLinkButton>
								<PrimaryButton
									disabled={props.isSubmitting}
									type='submit'
									onClick={e => onClickSubmit(props, e)}>
									{strings.registerNewUser}
								</PrimaryButton>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
