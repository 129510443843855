import React, { useEffect, useState } from 'react';
import { build } from '../../build';
import { BuildClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { clearCacheAndReloadApplication } from '../../infrastructure/Utils';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import './Settings.scss';

export const Settings = () => {
    const [currentBuild, setCurrentBuild] = useState<number>();
    const [isOutdated, setIsOutdated] = useState<boolean>(false);
    const buildClient = useClient(BuildClient);

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    []);

    const load = async () => {
        setCurrentBuild(await buildClient.current());
    }

    useEffect(() => {
        if (currentBuild === undefined) {
            setIsOutdated(false);
        } else if (build < currentBuild) {
            setIsOutdated(true)
        } else {
            setIsOutdated(false);
        }
    }, [currentBuild]);

    const onUpdate = () => {
        clearCacheAndReloadApplication();
    }

    return (
        <div className="df-col">
            <div>Current-Build: {currentBuild}</div>
            <div>Loaded-Build: {build}</div>
            {isOutdated ?
                <div className="font-error">You're using an old version of this application. Click Ctrl+F5 to load the latest version.</div>
                : <div className="font-success">Application is up-to-date!</div>}
            {isOutdated ?
                <div className="df-row-ac update-button">
                    <div>
                        <PrimaryButton small rectanglePadding onClick={() => onUpdate()}>Update application</PrimaryButton>
                    </div>
                </div> : null}
        </div>
    );
}