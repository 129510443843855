import React, { useState } from 'react';
import { empty, nameof } from '../infrastructure/Utils';
import { ITransportsQueryParams, ITransportSummary } from '../gen/ApiClients';

interface IProps {
    params: ITransportsQueryParams;
    setParams: (pars: ITransportsQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {
        pageIndex: 1,
        pageSize: 10,
        sortDirection: 'descending',
        sortProperty: nameof<ITransportSummary>('fileDate')
    },
    setParams: empty,
}

export const TransportsQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const TransportsQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<ITransportsQueryParams>(fallbackProps.params);

    return (
        <TransportsQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </TransportsQueryParamsContext.Provider>
    );
}