import React, { useState } from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { TextLinkButton } from '../buttons/TextLinkButton';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './BaseConfirmation.scss';
import { Responsive } from 'semantic-ui-react';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    title: string | undefined;
    description: string | JSX.Element | undefined;
    confirmText?: string;
    cancelText?: string;
    confirm: () => void | Promise<any>;
    cancel: () => void;
}

export const BaseConfirmation = ({ title, description, confirmText, cancelText, confirm, cancel, ...rest }: IProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const locContext = useLocalizationContext();
    confirmText = confirmText ? confirmText : locContext.yes;
    cancelText = cancelText ? cancelText : locContext.no;

    const submit = async () => {
        setIsSubmitting(true);
        await confirm();
        setIsSubmitting(false);
    }

    return (
        <div className={useCombineClassNames('df-col stretch-ver confirmation-modal', rest)}>
            <div className="df-col confirmation-modal-inner">
                <div className="mc-title">
                    {title}
                </div>
                <div className="mc-description">
                    {description}
                </div>
            </div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-e">
                    <div className="df-row-ac">
                        <TextLinkButton className="cancel-button" onClick={() => cancel()}>{cancelText}</TextLinkButton>
                        <PrimaryButton disabled={isSubmitting} showLoader onClick={() => submit()}>{confirmText}</PrimaryButton>
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col">
                    <PrimaryButton className="stretch-hor" onClick={() => confirm()}>{confirmText}</PrimaryButton>
                    <TextLinkButton className="cancel-button" onClick={() => cancel()}>{cancelText}</TextLinkButton>
                </div>
            </Responsive>
        </div>
    );
}