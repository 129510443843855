import React from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { Field } from 'formik';

interface IProps<T> extends IFieldGroupProps<T> {
	min?: number;
	max?: number;
	step?: number;
}

export function NumberInputField<T>({ min, max, step, ...rest }: IProps<T>) {
	return (
		<FieldGroup {...rest}>
			<Field
				type='number'
				name={rest.xName}
				min={min}
				max={max}
				step={step}
			/>
		</FieldGroup>
	);
}
