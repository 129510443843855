import { IUserInfo } from 'gen/ApiClients';
import { FunctionalClaim } from 'gen/FunctionalClaim';

export function calculateUserHasFunctionalClaim(user: IUserInfo, functionalClaim: FunctionalClaim): boolean {
	if (user.claims === undefined || user.claims === null || user.claims.length === 0) {
		return false;
	} else {
		return user.claims.indexOf(functionalClaim as FunctionalClaim) > -1;
	}
}
