import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from 'react';
import { IOption } from "../../../infrastructure/Models";
import { ListFilterItem } from './ListFilterItem';

interface IProps {
    trueText: string;
    falseText: string;
    icon: IconProp;
    label: string;
    value: boolean | undefined;
    onFilter: (value: boolean | undefined) => void;
}

export const BooleanFilterItem = ({trueText, falseText, icon, label, value, onFilter, ...rest}: IProps) => {
    const options: IOption<number>[] = [
            { key: 'true', text: trueText, value: 0},
            { key: 'false', text: falseText, value: 1},
    ]

    const handleFilter = (sel: number[]) => {
        if (sel.includes(0) && sel.includes(1)) {
            onFilter(undefined);
        } else if (sel.includes(0)) {
            onFilter(true);
        } else if (sel.includes(1)) {
            onFilter(false);
        } else {
            onFilter(undefined);
        }
    }

    return (
        <ListFilterItem 
            icon={icon}
            label={label}
            canSearch={false}
            options={options}
            selection={value === true ? [0] : value === false ? [1] : []}
            onFilter={handleFilter}/>
    );
}