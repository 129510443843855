import { executeApiCall } from 'infrastructure/executeApiCall';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import { CountUnreadMessagesContext } from '../../context/CountUnreadMessagesContext';
import { ModalContext } from '../../context/ModalContext';
import { OrdersQueryParamsContext } from '../../context/OrdersQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { IOrdersQueryParams, IOrderSummary, IOrderSummaryQueryResult, OrdersClient, OrdersQueryParams } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useTrigger } from '../../hooks/useTrigger';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createHeader, createSortableHeader } from '../common/table/TableUtils';
import { showSuccessToast } from '../common/toast/ToastNotification';
import { FilterOrders } from './FilterOrders';
import { Order } from './Order';
import './Orders.scss';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IOrderSummary>('createdDate'),
	sortDirection: 'descending',
};

export const Orders = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IOrderSummaryQueryResult>();
	const { params, setParams } = useContext(OrdersQueryParamsContext);
	const client = useClient(OrdersClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(WrapperSearchContext).init(`${strings.searchOnReference}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});
	const unreadContext = useContext(CountUnreadMessagesContext);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new OrdersQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const headers = [
		createSortableHeader<IOrderSummary>(strings.createdDate, 'createdDate', t => toDefaultFormat(t.createdDate)),
		createSortableHeader<IOrderSummary>(strings.servicePartner, 'servicePartnerName', t => t.servicePartnerName),
		createSortableHeader<IOrderSummary>(strings.description, 'driverName', t => t.driverName),
		createHeader<IOrderSummary>(strings.description, t => t.formatted),
		createHeader<IOrderSummary>(strings.price, t => <div>&euro; {t.totalPrice}</div>),
	];

	const viewDetail = async (inst: IOrderSummary) => {
		if (!inst.id) {
			return;
		}
		const detail = await tryCatchWithLoading(client.single(inst.id), wrapperLoaderContext.setLoading, strings.serverError);
		if (!detail) {
			return;
		}
		modalContext.open(
			<Order
				detail={detail}
				toggleIsRead={async () => {
					await executeApiCall(client.toggleIsRead(inst.id ? inst.id : ''), strings);
					hitTrigger();
					showSuccessToast(inst.isRead === true ? strings.markAsUnread : strings.markedAsRead);
					unreadContext.hitTrigger();
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>,
			true
		);
	};
	const formatRow = (inst: IOrderSummary) => {
		return inst.isRead === false ? 'font-bold ' : '';
	};

	return (
		<Card className='orders-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb orders-header'>
					<div className='title'>{strings.orders}</div>
				</div>
				<Divider className='no-margin' />
				<FilterOrders
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<IOrderSummary, IOrdersQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.order}
					onClickRow={inst => viewDetail(inst)}
					formatRow={inst => formatRow(inst)}
				/>
			</div>
		</Card>
	);
};
