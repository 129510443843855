import { IUsersQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { BooleanFilterItem } from '../common/filter/BooleanFilterItem';
import { FilterBar } from '../common/filter/FilterBar';
import React from 'react';

function isFiltered(pars: IUsersQueryParams): boolean {
	if (pars === undefined) {
		return false;
	} else if (pars.hasConfirmed !== undefined) {
		return true;
	} else {
		return false;
	}
}

interface IProps {
	params: IUsersQueryParams;
	onFilter: (params: IUsersQueryParams) => void;
}

export const FilterUsers = ({ params, onFilter }: IProps) => {
	const locContext = useLocalizationContext();

	const handleClear = () => {
		onFilter({
			...params,
			hasConfirmed: undefined,
			isBlocked: undefined,
		});
	};

	return (
		<FilterBar
			onClear={() => handleClear()}
			isFiltered={isFiltered(params)}>
			<div className='df-row-ac'>
				<BooleanFilterItem
					icon={['fas', 'star']}
					label={locContext.blockedQuestionMark}
					value={params.isBlocked}
					trueText={locContext.yes}
					falseText={locContext.no}
					onFilter={val => onFilter({ ...params, isBlocked: val })}
				/>
				<BooleanFilterItem
					icon={['fas', 'star']}
					label={locContext.hasConfirmedQuestionMark}
					value={params.hasConfirmed}
					trueText={locContext.yes}
					falseText={locContext.no}
					onFilter={val => onFilter({ ...params, hasConfirmed: val })}
				/>
			</div>
		</FilterBar>
	);
};
