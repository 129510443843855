import React, { useState } from 'react';
import './ToggleCheckbox.scss';

interface IProps {
    onChange?: (val: boolean) => void;
    externalProps?: IExternalProps;
}

interface IExternalProps {
    value: boolean;
    setValue: (val: boolean) => void;
}

export const ToggleCheckbox = ({ onChange, externalProps }: IProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleChange = () => {
        if (externalProps) {
            externalProps.setValue(!externalProps.value);
        } else {
            setIsChecked(!isChecked);
            if (onChange){
                onChange(!isChecked);
            }
        }
    }

    return (
        <label className="toggle-checkbox">
            <input type="checkbox" checked={externalProps ? externalProps.value : isChecked} onChange={handleChange} />
            <div className="slider"></div>
        </label>
    );
}