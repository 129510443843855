import { useLocalStorage } from 'hooks/useLocalStorage';
import { IUserInfo } from '../gen/ApiClients';
import { TokenStore } from '../infrastructure/TokenStore';
import { AuthContext } from './AuthContext';
import { ILoginResponse } from './ILoginResponse';
import React from 'react';

export const AuthProvider = (props: any) => {
	const [user, setUser] = useLocalStorage<IUserInfo>('es-admin-user-info-ls-key');

	const login = async (response: ILoginResponse) => {
		if (TokenStore.getToken() !== response.token) {
			TokenStore.setToken(response.token);
		}
		setUser(response.user);
	};

	const logout = () => {
		TokenStore.removeToken();
		setUser(undefined);
	};

	return (
		<AuthContext.Provider
			value={{
				hasToken: TokenStore.getToken() ? true : false,
				user: user,
				login: login,
				logout: logout,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};
