import { useContext } from "react";
import { LocalizationContext } from "../context/LocalizationContext";
import { IStrings } from "../localization/IStrings";

export interface IStringsWithFormat extends IStrings {
    formatString: (key: keyof IStrings, ...val: Array<string | number | undefined>) => string;
}

export function useLocalizationContext(): IStringsWithFormat {
    const context = useContext(LocalizationContext);
    return {...context.strings, formatString: context.formatString};
}