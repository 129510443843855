import React from 'react';
import { Field } from 'formik';
import { FieldGroup, IFieldGroupProps } from './FieldGroup';

interface IProps<T> extends IFieldGroupProps<T>{
    disabled?: boolean;
}

export function TextInputField<T>({ disabled = false, ...props}: IProps<T>) {
    return (
        <FieldGroup {...props}>
            <Field type="text" disabled={disabled} name={props.xName} placeholder={props.placeholder} />
        </FieldGroup>
    );
}


