import React, { useEffect, useState } from 'react';
import { CountUnreadMessagesClient } from '../gen/ApiClients';
import { useClient } from '../hooks/useClient';
import { useTrigger } from '../hooks/useTrigger';
import { empty } from '../infrastructure/Utils';

interface IProps {
    hitTrigger: () => void;
    countOrders: number;
    countDisputes: number;
}

const fallbackProps: IProps = {
    hitTrigger: empty,
    countDisputes: 0,
    countOrders: 0,
}

export const CountUnreadMessagesContext = React.createContext<IProps>(fallbackProps);

export const CountUnreadMessagesProvider = ({ children }: any) => {
    const [trigger, hitTrigger] = useTrigger();
    const [countOrders, setCountOrders] = useState<number>(0);
    const [countDisputes, setCountDisputes] = useState<number>(0);

    const client = useClient(CountUnreadMessagesClient);
    
    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [trigger]);

    const load = async () => {
        const x = await client.unread();
        setCountDisputes(x.disputes ? x.disputes : 0);
        setCountOrders(x.orders ? x.orders : 0);
    }

    return (
        <CountUnreadMessagesContext.Provider
            value={{
                hitTrigger: hitTrigger,
                countDisputes: countDisputes,
                countOrders: countOrders,
            }}>
            {children}
        </CountUnreadMessagesContext.Provider>
    );
}