import { IconButton } from 'components/common/buttons/IconButton';
import { PrimaryButton } from 'components/common/buttons/PrimaryButton';
import { BaseConfirmation } from 'components/common/modal/BaseConfirmation';
import { showErrorToast, showSuccessToast } from 'components/common/toast/ToastNotification';
import { ModalContext } from 'context/ModalContext';
import { useRecordContext } from 'context/records/useRecordContext';
import { FunctionalClaim } from 'gen/FunctionalClaim';
import { UserRole } from 'gen/UserRole';
import { useTrigger } from 'hooks/useTrigger';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Checkbox, Divider } from 'semantic-ui-react';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import {
	IEuroSprintersUserDto,
	IEuroSprintersUserDtoQueryResult,
	IUsersQueryParams,
	UsersCommandClient,
	UsersQueryClient,
	UsersQueryParams,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, tryCatchWithLoading } from '../../infrastructure/Utils';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createEmptyHeader, createHeader, createSortableHeader } from '../common/table/TableUtils';
import { PatchUserRoleForm } from './PatchUserRoleForm';
import { FilterUsers } from './FilterUsers';
import { RegisterUserForm } from './RegisterUserForm';
import { UsersQueryContext } from './UsersQueryContext';
import { PatchClaimsForm } from './PatchClaimsForm';
import { executeApiCall } from 'infrastructure/executeApiCall';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IEuroSprintersUserDto>('email'),
	sortDirection: 'ascending',
};

export const UsersList = () => {
	const { userRoleRecord, functionalClaimRecord } = useRecordContext();
	const modalContext = useContext(ModalContext);
	const commandClient = useClient(UsersCommandClient);
	const [trigger, hitTrigger] = useTrigger();
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const strings = useLocalizationContext();
	const client = useClient(UsersQueryClient);
	const [queryResult, setQueryResult] = useState<IEuroSprintersUserDtoQueryResult>();
	const { params, setParams } = useContext(UsersQueryContext);
	useContext(WrapperSearchContext).init(`${strings.search}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		const t = await tryCatchWithLoading(client.query(new UsersQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError);
		setQueryResult(t);
	};

	const headers = [
		createHeader<IEuroSprintersUserDto>(strings.blockedQuestionMark, t => (
			<Checkbox
				toggle
				checked={t.isBlocked === false}
				onClick={() => onToggleIsBlocked(t)}
			/>
		)),
		createSortableHeader<IEuroSprintersUserDto>(strings.email, 'email', t => t.email!),
		createSortableHeader<IEuroSprintersUserDto>(strings.firstName, 'firstName', t => t.firstName!),
		createSortableHeader<IEuroSprintersUserDto>(strings.lastName, 'lastName', t => t.lastName!),
		createHeader<IEuroSprintersUserDto>(strings.role, user => (
			<div className='df-row'>
				<div>{userRoleRecord[user.role as UserRole]}</div>
				<IconButton
					color='gray'
					size='large'
					className='edit-button'
					icon={['far', 'edit']}
					onClick={() => onChangeRole(user)}
					style={{ marginLeft: 8 }}
				/>
			</div>
		)),
		createHeader<IEuroSprintersUserDto>(strings.rights, user => (
			<div className='df-row'>
				<div className='df-col'>
					{user.claims?.map(claim => (
						<div key={claim}>{functionalClaimRecord[claim as FunctionalClaim]}</div>
					))}
				</div>
				{user.claims?.length === 0 && (
					<div>
						<em>{strings.noRightsGranted}</em>
					</div>
				)}
				<IconButton
					color='gray'
					size='large'
					className='edit-button'
					icon={['far', 'edit']}
					onClick={() => onChangeClaims(user)}
					style={{ marginLeft: 8 }}
				/>
			</div>
		)),
		createEmptyHeader<IEuroSprintersUserDto>(
			'reg-token',
			t =>
				t.hasConfirmed ? (
					<></>
				) : (
					<PrimaryButton
						small
						onClick={() => onResendRegistrationToken(t)}>
						{strings.resendRegistrationLink}
					</PrimaryButton>
				),
			false,
			true
		),
		createEmptyHeader<IEuroSprintersUserDto>(
			'actions',
			t => (
				<div className='df-row-ac'>
					<IconButton
						color='gray'
						size='large'
						icon={['far', 'trash-alt']}
						onClick={() => deleteUser(t)}
					/>
				</div>
			),
			false,
			true
		),
	];

	const onToggleIsBlocked = async (user: IEuroSprintersUserDto) => {
		modalContext.open(
			<BaseConfirmation
				title={user.isBlocked ? strings.unblockUser : strings.blockUser}
				description={user.isBlocked ? strings.unblockUserConfirmation : strings.blockUserConfirmation}
				cancel={() => modalContext.close()}
				confirmText={user.isBlocked ? strings.yesCommaUnblock : strings.yesCommaBlock}
				confirm={async () => {
					const r = user.isBlocked
						? await executeApiCall(commandClient.unBlock(user.id!), strings)
						: await executeApiCall(commandClient.block(user.id!), strings);
					if (r.hasError) {
						showErrorToast(r.errorMessage!);
					}
					hitTrigger();
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const registerNewUser = () => {
		modalContext.open(
			<RegisterUserForm
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			false
		);
	};

	const onChangeRole = (user: IEuroSprintersUserDto) => {
		modalContext.open(
			<PatchUserRoleForm
				user={user}
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			false
		);
	};

	const onChangeClaims = (user: IEuroSprintersUserDto) => {
		modalContext.open(
			<PatchClaimsForm
				user={user}
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			false
		);
	};

	const onResendRegistrationToken = (user: IEuroSprintersUserDto) => {
		modalContext.open(
			<BaseConfirmation
				title={strings.resendRegistrationLink}
				description={strings.resendRegistrationLinkConfirmation}
				cancel={() => modalContext.close()}
				confirmText={strings.yes}
				confirm={async () => {
					const r = await executeApiCall(commandClient.resendRegistrationToken(user.id!), strings);
					if (r.hasError) {
						showErrorToast(r.errorMessage!);
					}
					hitTrigger();
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const deleteUser = (inst: IEuroSprintersUserDto) => {
		modalContext.open(
			<BaseConfirmation
				title={strings.deleteUserQuestion}
				description={strings.confirmDeleteUser}
				cancel={() => modalContext.close()}
				confirmText={strings.yesDelete}
				confirm={async () => {
					const r = await executeApiCall(commandClient.delete(inst.id!), strings);
					if (r.hasError) {
						showErrorToast(r.errorMessage!);
					} else {
						showSuccessToast(strings.userDeleted);
					}
					hitTrigger();
					modalContext.close();
				}}
			/>,
			false
		);
	};

	return (
		<Card className='base-transports-card'>
			<div className='df-col stretch-ver'>
				<div
					className='df-row-ac jc-sb'
					style={{ margin: 16 }}>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							onClick={() => registerNewUser()}>
							+ {strings.registerNewUser}
						</PrimaryButton>
					</div>
				</div>
				<Divider className='no-margin' />
				<FilterUsers
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<IEuroSprintersUserDto, IUsersQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.users}
					formatRow={t => (t.canLogin === false ? 'font-light ' : '')}
				/>
			</div>
		</Card>
	);
};
