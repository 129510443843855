import { FormikProps, FormikActions } from 'formik';

export function setFieldTouched<T>(str: keyof T, props: FormikProps<T> | FormikActions<T>){
    props.setFieldTouched(str.toString());
}
export function setFieldError<T>(str: keyof T, message: string, props: FormikActions<T>){
    props.setFieldError(str.toString(), message);
}
export function setFieldValue<T>(str: keyof T, val: any, props: FormikProps<T>){
    props.setFieldValue(str.toString(), val);
}
