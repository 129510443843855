import React from 'react';
import { IUserInfo } from '../gen/ApiClients';
import { ILoginResponse } from './ILoginResponse';

interface AuthenticationProps {
	hasToken: boolean;
	user: IUserInfo | undefined;
	login: (response: ILoginResponse) => void;
	logout: VoidFunction;
}

const inititalState: AuthenticationProps = {
	hasToken: false,
	user: undefined,
	login: () => {},
	logout: () => {},
};

export const AuthContext = React.createContext<AuthenticationProps>(inititalState);
