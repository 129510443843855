import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import './FormDialog.scss';

interface IProps extends React.HTMLProps<HTMLDivElement>{
}

export const FormDialog = ({children, ...rest}: IProps) => {
    return (
        <div {...rest} className={useCombineClassNames('form-dialog', rest)}>
            {children}
        </div>
    );
}