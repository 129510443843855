import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButton, IIconButtonProps } from './IconButton';

interface IProps extends IIconButtonProps{
    trueIcon: IconProp;
    falseIcon: IconProp;
    checked: boolean;
    toggle: (e: any) => void;
}

export const ToggleIconButton = ({trueIcon, falseIcon, checked, toggle, ...rest}: IProps) => {
    return (
        checked ? 
            <IconButton {...rest} onClick={toggle} icon={trueIcon} /> 
            : <IconButton {...rest} onClick={toggle} icon={falseIcon} />
    );
}