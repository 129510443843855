import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { IconButton } from '../buttons/IconButton';
import { Popup } from 'semantic-ui-react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import './FilterBar.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    onClear: () => void;
    isFiltered: boolean;
}

export const FilterBar = ({ children, onClear, isFiltered, ...rest }: IProps) => {
    const locContext = useLocalizationContext();
    return (
        <div {...rest} className={useCombineClassNames("df-row-ac filter-bar", rest)} >
            <div className="filter-on-text">{locContext.filterOn}:</div>
            {children}
            {isFiltered ?
                <Popup
                    content={locContext.deleteFilter}
                    basic
                    trigger={<IconButton
                        className="filter-clear-button"
                        icon={['fas', 'times']}
                        onClick={onClear} />} />
                : null}
        </div>
    );
}