import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CustomListItem.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    checked: boolean;
    label: string;
    onToggle: () => void;
    showCheckbox?: boolean;
}

export const CustomListItem = ({ label, checked, onToggle, showCheckbox = true, ...rest }: IProps) => {
    return (
        <div {...rest} className={useCombineClassNames(`df-row-ac custom-list-item${checked ? ' cli-checked' : ''}`, rest)} onClick={() => onToggle()} >
            {showCheckbox ?
                <div className={`df-row-ac jc-c cli-icon${checked ? ' cli-checked' : ''}`}>
                    {checked ?
                        <FontAwesomeIcon className="svg-icon" icon={['fas', 'check']} />
                        : null}
                </div>
                : null}
            <div className={`cli-label${checked ? ' cli-checked' : ''}`}>{label}</div>
        </div>
    );
}