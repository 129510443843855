import { library } from '@fortawesome/fontawesome-svg-core';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { LoginPage } from './components/auth/LoginPage';
import { AuthContext } from './context/AuthContext';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouterProvider } from './context/BrowserRouterProvider';
// library stuff for font-awesome
import { faComments, faEdit, faEye, faEyeSlash, faSquare, faStar as faStarRegular, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
	faArrowLeft,
	faCalendar,
	faCarSide,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faEllipsisV,
	faExclamationTriangle,
	faFileSignature,
	faIndustry,
	faInfo,
	faInfoCircle,
	faPlus,
	faReceipt,
	faSearch,
	faStar,
	faTimes,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { AppUpdateWall } from 'components/AppUpdateWall';
import { RecordProvider } from 'context/records/RecordProvider';
import { ProtectedRoute } from 'infrastructure/ProtectedRoute';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { Wrapper } from './components/Wrapper';
import { ConfirmUserPage } from './components/auth/ConfirmUserPage';
import { ForgotPasswordPage } from './components/auth/ForgotPasswordPage';
import { ResetPasswordPage } from './components/auth/ResetPasswordPage';
import { LocalizationProvider } from './context/LocalizationContext';
import * as routes from './routes';
import React from 'react';

library.add(
	faEye as any,
	faEyeSlash as any,
	faSearch as any,
	faStar as any,
	faStarRegular as any,
	faChevronUp as any,
	faChevronDown as any,
	faEdit as any,
	faEllipsisV as any,
	faUsers as any,
	faCheckSquare as any,
	faSquare as any,
	faCheck as any,
	faCalendar as any,
	faInfo as any,
	faCarSide as any,
	faTimes as any,
	faInfoCircle as any,
	faIndustry as any,
	faPlus as any,
	faCheckCircle as any,
	faExclamationTriangle as any,
	faReceipt as any,
	faChevronLeft as any,
	faChevronRight as any,
	faComments as any,
	faFileSignature as any,
	faTrashAlt as any,
	faArrowLeft as any
);

export function App() {
	return (
		<BrowserRouterProvider>
			<LocalizationProvider>
				<RecordProvider>
					<AuthProvider>
						<ToastContainer
							position={toast.POSITION.TOP_CENTER}
							autoClose={3000}
							hideProgressBar={false}
							newestOnTop={true}
							closeOnClick
							closeButton={false}
							transition={Slide}
							draggable={false}
						/>
						<AppUpdateWall>
							<AuthContext.Consumer>
								{({ hasToken }) => (
									<Switch>
										<Route
											exact
											path={routes.LoginRoute}
											component={LoginPage}
										/>
										<Route
											exact
											path={routes.ConfirmUserRoute}
											component={ConfirmUserPage}
										/>
										<Route
											exact
											path={routes.ForgotPasswordRoute}
											component={ForgotPasswordPage}
										/>
										<Route
											exact
											path={routes.ResetPasswordRoute}
											component={ResetPasswordPage}
										/>
										<ProtectedRoute
											isAuth={hasToken}
											redirectRoute={routes.LoginRoute}
											component={Wrapper}
										/>
									</Switch>
								)}
							</AuthContext.Consumer>
						</AppUpdateWall>
					</AuthProvider>
				</RecordProvider>
			</LocalizationProvider>
		</BrowserRouterProvider>
	);
}
