import { Logo } from 'components/common/Logo';
import React from 'react';

interface IProps {
	children: any;
	title: string;
	subTitle: string;
}

export const EmptyPageWithLogo = ({ title, subTitle, children }: IProps) => {
	return (
		<div className='df-col empty-page'>
			<div
				className='df-row-ac jc-sb'
				style={{ padding: '20px 40px', marginBottom: 30 }}>
				<Logo />
			</div>
			<div className='df-col-ac'>
				<span style={{ fontSize: '30px', fontWeight: 'bold', lineHeight: '46px' }}>{title}</span>
				<span style={{ fontSize: '16px', lineHeight: '25px', marginBottom: 40 }}>{subTitle}</span>
				{children}
			</div>
		</div>
	);
};
