import React, { useContext } from 'react';
import { FilterDataContext } from '../../context/FilterDataContext';
import { INotificationsQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toOptions } from '../../infrastructure/Utils';
import { BooleanFilterItem } from '../common/filter/BooleanFilterItem';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { FilterBar } from '../common/filter/FilterBar';
import { ListFilterItem } from '../common/filter/ListFilterItem';

interface IProps {
    params: INotificationsQueryParams;
    onFilter: (params: INotificationsQueryParams) => void;
}

function isFiltered(pars: INotificationsQueryParams): boolean {
    if (pars === undefined) {
        return false;
    } else if ((pars.clientIds && pars.clientIds.length > 0) || (pars.servicePartnerIds && pars.servicePartnerIds.length > 0) || pars.isForClients !== undefined) {
        return true;
    } else if (pars.createdAfterDate || pars.createdBeforeDate) {
        return true;
    } else {
        return false;
    }
}

export const FilterNotifications = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const filterDataContext = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, servicePartnerIds: [], clientIds: [], createdAfterDate: undefined, createdBeforeDate: undefined, isForClients: undefined});
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac">
                <BooleanFilterItem
                    icon={['fas', 'star']}
                    label={locContext.targetAudience}
                    value={params.isForClients}
                    trueText={locContext.forClients}
                    falseText={locContext.forServicePartners}
                    onFilter={(val) => onFilter({ ...params, isForClients: val })} />
                <ListFilterItem<string>
                    icon={['fas', 'car-side']}
                    label={locContext.servicePartner}
                    options={toOptions(filterDataContext.allServicePartners)}
                    selection={params.servicePartnerIds ? params.servicePartnerIds : []}
                    onFilter={(val) => onFilter({ ...params, servicePartnerIds: val })} />
                <DateFilterItem
                    undefinedLabel={locContext.createdDate}
                    afterDate={params.createdAfterDate}
                    beforeDate={params.createdBeforeDate}
                    onFilter={(val) => onFilter({ ...params, createdAfterDate: val.after, createdBeforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}