import { useLocalizationContext } from 'hooks/useLocalizationContext';
import React, { useMemo } from 'react';
import { createFunctionalClaimRecord } from './createFunctionalClaimRecord';
import { createLoadOrUnloadActionTypeRecord } from './createLoadOrUnloadActionTypeRecord';
import { createRuleLogEntryTypeRecord } from './createRuleLogEntryTypeRecord';
import { createUserRoleRecord } from './createUserRoleRecord';
import { RecordContext } from './RecordContext';

export const RecordProvider = ({ children }: any) => {
	const strings = useLocalizationContext();
	const functionalClaimRecord = useMemo(() => createFunctionalClaimRecord(strings), [strings]);
	const userRoleRecord = useMemo(() => createUserRoleRecord(strings), [strings]);
	const loadOrUnloadActionTypeRecord = useMemo(() => createLoadOrUnloadActionTypeRecord(strings), [strings]);
	const ruleLogEntryTypeRecord = useMemo(() => createRuleLogEntryTypeRecord(strings), [strings]);

	return (
		<RecordContext.Provider
			value={{
				functionalClaimRecord: functionalClaimRecord,
				userRoleRecord: userRoleRecord,
				loadOrUnloadActionTypeRecord: loadOrUnloadActionTypeRecord,
				ruleLogEntryTypeRecord: ruleLogEntryTypeRecord,
			}}>
			{children}
		</RecordContext.Provider>
	);
};
