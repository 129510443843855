import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import { RouterContext } from '../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { BuildClient, ITransportDetail, TransportsClient } from '../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { IconButton } from '../common/buttons/IconButton';
import { StopsViewComponent } from './StopsViewComponent';
import './Transport.scss';

export const Transport = ({ match }: any) => {
	const locContext = useLocalizationContext();
	const fileNumber = match.params.fileNumber;
	const client = useClient(TransportsClient);
	const [detail, setDetail] = useState<ITransportDetail>();
	const loaderContext = useContext(WrapperFullScreenLoadContext);
	useContext(WrapperSearchContext).hide();
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);
	useContext(WrapperSearchContext).hideAndShowGoBackButton();
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			loadDetail();
		},
		// eslint-disable-next-line
		[fileNumber]
	);

	const loadDetail = async () => {
		if (fileNumber) {
			setDetail(await tryCatchWithLoading(client.detail(fileNumber), loaderContext.setLoading, locContext.serverError));
		}
	};

	const downloadPod = async (detail: ITransportDetail) => {
		loaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		const downloadUrl = `${baseUrl}/api/transports/${detail.fileNumber}/downloadPod`;
		downloadClient.download(downloadUrl, `POD_${detail.fileNumber}.pdf`, () => loaderContext.setLoading(false));
	};

	if (detail === undefined) {
		return null;
	}

	const formatClient = (): string => {
		if (detail.clientId) {
			return `${detail.clientId} (${detail.clientName})`;
		} else {
			return '';
		}
	};
	const formatServicePartner = (): string => {
		if (detail.servicePartnerId) {
			return `${detail.servicePartnerId} (${detail.servicePartnerName})`;
		} else {
			return '';
		}
	};

	return (
		<Card className='transport-detail-card df-col'>
			<div className='df-row'>
				<IconButton
					size='very-large'
					icon={['fas', 'file-signature']}
					className='download-button'
					color={detail.isTrackAndTraceAvailable === true ? 'success' : 'gray'}
					popupText={locContext.downloadTrackAndTrace}
					onClick={() => downloadPod(detail)}
					disabled={detail.isTrackAndTraceAvailable === false}
				/>
			</div>
			<div className='label'>{locContext.status}</div>
			<div className='info'>{detail.status}</div>
			<div className='label'>{locContext.fileDate}</div>
			<div className='info'>{toDefaultFormat(detail.fileDate)}</div>
			<div className='label'>{locContext.firstStopDate}</div>
			<div className='info'>{toDefaultFormat(detail.dateFirstStop)}</div>
			<div className='label'>{locContext.department}</div>
			<div className='info'>{detail.department}</div>
			<div className='label'>{locContext.subDepartment}</div>
			<div className='info'>{detail.subDepartment}</div>
			<div className='label'>{locContext.client}</div>
			<div className='info'>{formatClient()}</div>
			<div className='label'>{locContext.contact}</div>
			<div className='info'>{detail.contactId}</div>
			<div className='label'>{locContext.servicePartner}</div>
			<div className='info'>{formatServicePartner()}</div>
			<div className='label'>{locContext.fileNumber}</div>
			<div className='info'>{detail.fileNumber}</div>
			<StopsViewComponent
				firstStop={detail.firstStop}
				extraStops={detail.extraStops}
				lastStop={detail.lastStop}
			/>
			<div className='label'>{locContext.costAmount}</div>
			<div className='info'>&euro; {detail.costAmount}</div>
			<div className='df-row'>
				{detail.revenues
					? detail.revenues.map(t => (
							<div
								className='df-col single-price-calc'
								key={t.description}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>
			<div className='label'>{locContext.serviceAmount}</div>
			<div className='info'>&euro; {detail.serviceAmount}</div>
			<div className='df-row'>
				{detail.costs
					? detail.costs.map(t => (
							<div
								className='df-col single-price-calc'
								key={t.description}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>
			<div className='label'>{locContext.discounts}</div>
			<div className='df-row'>
				{detail.discounts
					? detail.discounts.map(t => (
							<div
								className='df-col single-price-calc'
								key={t.description}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>

			<div className='label'>{locContext.route}</div>
			<div className='info'>{detail.totalDistance} km</div>
		</Card>
	);
};
