import React, { useContext } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterBar } from '../common/filter/FilterBar';
import { BooleanFilterItem } from '../common/filter/BooleanFilterItem';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { FilterDataContext } from '../../context/FilterDataContext';
import { toOptions } from '../../infrastructure/Utils';
import { ListFilterItem } from '../common/filter/ListFilterItem';
import { IDisputeQueryParams } from '../../gen/ApiClients';

interface IProps {
    params: IDisputeQueryParams;
    onFilter: (params: IDisputeQueryParams) => void;
}

function isFiltered(pars: IDisputeQueryParams): boolean {
    if (pars === undefined) {
        return false;
    } else if (pars.isRead === true || pars.isRead === false) {
        return true;
    } else if (pars.servicePartnerIds && pars.servicePartnerIds.length > 0) {
        return true;
    } else if (pars.afterDate || pars.beforeDate) {
        return true;
    } else {
        return false;
    }
}

export const FilterDisputes = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const filterDataContext = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, servicePartnerIds: [], afterDate: undefined, beforeDate: undefined, isRead: undefined});
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac">
                <BooleanFilterItem
                    icon={['fas', 'star']}
                    label={locContext.isRead}
                    value={params.isRead}
                    trueText={locContext.read}
                    falseText={locContext.unread}
                    onFilter={(val) => onFilter({ ...params, isRead: val })} />
                <ListFilterItem<string>
                    icon={['fas', 'car-side']}
                    label={locContext.servicePartner}
                    options={toOptions(filterDataContext.allServicePartners)}
                    selection={params.servicePartnerIds ? params.servicePartnerIds : []}
                    onFilter={(val) => onFilter({ ...params, servicePartnerIds: val })} />
                <DateFilterItem
                    undefinedLabel={locContext.createdDate}
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => onFilter({ ...params, afterDate: val.after, beforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}