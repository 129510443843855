import { executeApiCall } from 'infrastructure/executeApiCall';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider, Popup } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { NotificationsQueryParamsContext } from '../../context/NotificationsQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import {
	AutoGenerateModel,
	INotification,
	INotificationsQueryParams,
	INotificationQueryResult,
	NotificationModel,
	NotificationsClient,
	NotificationsQueryParams,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useTrigger } from '../../hooks/useTrigger';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { IconButton } from '../common/buttons/IconButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createEmptyHeader, createHeader, createSortableHeader } from '../common/table/TableUtils';
import { showSuccessToast } from '../common/toast/ToastNotification';
import { AutoGenerateForm } from './AutoGenerateForm';
import { FilterNotifications } from './FilterNotifications';
import { NotificationDetailComponent } from './NotificationDetailComponent';
import { NotificationForm } from './NotificationForm';
import './Notifications.scss';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<INotification>('createdDate'),
	sortDirection: 'descending',
};

export const Notifications = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<INotificationQueryResult>();
	const { params, setParams } = useContext(NotificationsQueryParamsContext);
	const client = useClient(NotificationsClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(WrapperSearchContext).init(`${strings.searchOnTitleOrDescription}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new NotificationsQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const renderShowOnDate = (inst: INotification) => {
		if (inst.showAlways) {
			return <div>{strings.always}</div>;
		} else if (inst.showAfterDate && inst.showBeforeDate) {
			return (
				<div>
					{toDefaultFormat(inst.showAfterDate)}-{toDefaultFormat(inst.showBeforeDate)}
				</div>
			);
		} else if (inst.showAfterDate) {
			return <div>{toDefaultFormat(inst.showAfterDate)}-...</div>;
		} else if (inst.showBeforeDate) {
			return <div>...-{toDefaultFormat(inst.showBeforeDate)}</div>;
		}
		return <div></div>;
	};
	const renderTargetAudience = (inst: INotification) => {
		if (inst.servicePartnerName) {
			return inst.servicePartnerName;
		} else if (inst.isForClients) {
			return strings.allClients;
		} else {
			return strings.allServicePartners;
		}
	};
	const renderDescription = (inst: INotification) => {
		if (inst.description) {
			if (inst.description.length > 10) {
				return (
					<Popup
						content={inst.description}
						basic
						trigger={<div>{inst.description.substring(0, 10) + ' ...'}</div>}
					/>
				);
			} else {
				return inst.description;
			}
		}
		return '';
	};

	const renderActions = (inst: INotification) => {
		return (
			<div className='df-row-ac'>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => deleteNotification(inst)}
				/>
				<IconButton
					color='gray'
					size='large'
					className='edit-button'
					icon={['far', 'edit']}
					onClick={() => editNotification(inst)}
				/>
			</div>
		);
	};

	const headers = [
		createSortableHeader<INotification>(strings.createdDate, 'createdDate', t => toDefaultFormat(t.createdDate)),
		createHeader<INotification>(strings.title, t => t.title),
		createHeader<INotification>(strings.description, t => renderDescription(t)),
		createHeader<INotification>(strings.showOnDate, t => renderShowOnDate(t)),
		createHeader<INotification>(strings.targetAudience, t => renderTargetAudience(t)),
		createHeader<INotification>(strings.status, t => t.status),
		createEmptyHeader<INotification>('actions', t => renderActions(t), false, true),
	];

	const deleteNotification = (inst: INotification) => {
		modalContext.open(
			<BaseConfirmation
				title={strings.deleteNotification}
				description={strings.confirmDeleteNotification}
				cancel={() => modalContext.close()}
				confirmText={strings.yesDelete}
				confirm={async () => {
					await executeApiCall(client.delete(inst.id ? inst.id : ''), strings);
					hitTrigger();
					showSuccessToast(strings.notificationDeleted);
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const editNotification = (inst: INotification) => {
		modalContext.open(
			<NotificationForm
				notification={inst}
				onSubmit={async val => {
					await executeApiCall(client.update(inst.id ? inst.id : '', new NotificationModel(val)), strings);
					hitTrigger();
					showSuccessToast(strings.notificationChanged);
					modalContext.close();
				}}
				onCancel={() => modalContext.close()}
			/>,
			false
		);
	};

	const addNotification = () => {
		modalContext.open(
			<NotificationForm
				onSubmit={async val => {
					await executeApiCall(client.insert(new NotificationModel(val)), strings);
					showSuccessToast(strings.notificationAdded);
					modalContext.close();
					hitTrigger();
				}}
				onCancel={() => modalContext.close()}
			/>,
			false
		);
	};
	const viewDetail = async (inst: INotification) => {
		if (!inst.id) {
			return;
		}
		const detail = await tryCatchWithLoading(client.getSingle(inst.id), wrapperLoaderContext.setLoading, strings.serverError);
		modalContext.open(<NotificationDetailComponent detail={detail} />, true);
	};
	const autoGenerate = () => {
		modalContext.open(
			<AutoGenerateForm
				onSubmit={async val => {
					await executeApiCall(client.autoGenerate(new AutoGenerateModel(val)), strings);
					hitTrigger();
					modalContext.close();
				}}
				onCancel={() => modalContext.close()}
			/>,
			false
		);
	};
	const formatRow = (inst: INotification) => {
		const x1 = inst.status === 'Unread' ? 'font-bold ' : '';
		const x2 = inst.isHighPriority ? 'font-error' : '';
		return x1 + x2;
	};

	return (
		<Card className='notifications-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb notifications-header'>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							onClick={() => addNotification()}>
							+ {strings.addNotification}
						</PrimaryButton>
					</div>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							outline
							onClick={() => autoGenerate()}>
							{strings.autoGenerateNotifications}
						</PrimaryButton>
					</div>
				</div>
				<Divider className='no-margin' />
				<FilterNotifications
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<INotification, INotificationsQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.notification}
					onClickRow={inst => viewDetail(inst)}
					formatRow={inst => formatRow(inst)}
				/>
			</div>
		</Card>
	);
};
