import React, { useState } from 'react';
import { empty } from '../infrastructure/Utils';

interface IProps {
    placeholder: string;
    searchText: string | undefined;
    onSearch: (str: string) => void;
    init: (ph: string, st: string | undefined, cb: (st: string) => void) => void;
    hide: () => void;
    isHidden: boolean;
    hideAndShowGoBackButton: () => void;
    isShowGoBack: boolean;
}

const fallback: IProps = {
    placeholder: '',
    searchText: '',
    onSearch: empty,
    init: empty,
    isHidden: false,
    hide: empty,
    hideAndShowGoBackButton: empty,
    isShowGoBack: false,
}

export const WrapperSearchContext = React.createContext<IProps>(fallback);

export const WrapperSearchProvider = ({ children }: any) => {
    const [isSearchHidden, setIsSearchHidden] = useState<boolean>(false);
    const [isShowGoBack, setIsShowGoBack] = useState<boolean>(false);
    const [placeholder, setPlaceholder] = useState<string>('');
    const [searchText, setSearchText] = useState<string | undefined>('');

    let callback: (str: string) => void;
    
    function init(ph: string, st: string | undefined, cb: (str: string) => void) {
        setIsSearchHidden(false);
        setIsShowGoBack(false);
        if (placeholder !== ph) {
            setPlaceholder(ph);
            setSearchText(st);
        } else {
            callback = cb;
        }
    }

    const onSearch = (str: string) => {
        if (str !== searchText) {
            setSearchText(str);
            callback(str);
        }
    }

    return (
        <WrapperSearchContext.Provider
            value={{
                placeholder: placeholder,
                searchText: searchText,
                onSearch: onSearch,
                init: init,
                isHidden: isSearchHidden,
                hide: () => {setIsSearchHidden(true); setIsShowGoBack(false)},
                hideAndShowGoBackButton: () => { setIsSearchHidden(true); setIsShowGoBack(true); },
                isShowGoBack: isShowGoBack,
            }}>
            {children}
        </WrapperSearchContext.Provider>
    );
}
