import React, { useState, useEffect, useContext } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { ITransportSummary, TransportsClient, ITransportSummaryQueryResult, TransportsQueryParams, ITransportsQueryParams } from '../../gen/ApiClients';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useClient } from '../../hooks/useClient';
import { RouterContext } from '../../context/RouterContext';
import { TransportsQueryParamsContext } from '../../context/TransportsQueryParamsContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { createSortableHeader, createHeader } from '../common/table/TableUtils';
import { FilterTransports } from './FilterTransports';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import * as routes from '../../routes';
import './Transports.scss';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<ITransportSummary>('fileDate'),
	sortDirection: 'descending',
};

export const Transports = () => {
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const locContext = useLocalizationContext();
	const client = useClient(TransportsClient);
	const routerContext = useContext(RouterContext);
	const [queryResult, setQueryResult] = useState<ITransportSummaryQueryResult>();
	const { params, setParams } = useContext(TransportsQueryParamsContext);
	useContext(WrapperSearchContext).init(`${locContext.searchOn} ${locContext.fileNumber}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			loadTransports();
		},
		// eslint-disable-next-line
		[params]
	);

	const loadTransports = async () => {
		const t = await tryCatchWithLoading(client.query(new TransportsQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError);
		setQueryResult(t);
	};

	const headers = [
		createSortableHeader<ITransportSummary>(locContext.fileDate, 'fileDate', t => toDefaultFormat(t.fileDate)),
		createSortableHeader<ITransportSummary>(locContext.firstStopDate, 'firstStopDate', t => toDefaultFormat(t.firstStopDate)),
		createSortableHeader<ITransportSummary>(locContext.fileNumber, 'fileNumber', t => t.fileNumber),
		createHeader<ITransportSummary>(locContext.servicePartner, t => t.servicePartnerId),
		createHeader<ITransportSummary>(locContext.client, t => t.clientId),
		createHeader<ITransportSummary>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
		createSortableHeader<ITransportSummary>(locContext.reference, 'reference', t => t.reference),
		createSortableHeader<ITransportSummary>(locContext.department, 'department', t => t.department),
		createHeader<ITransportSummary>(locContext.costAmount, t => <div>&euro; {t.costAmount}</div>),
		createHeader<ITransportSummary>(locContext.serviceAmount, t => <div>&euro; {t.serviceAmount}</div>),
		createSortableHeader<ITransportSummary>(locContext.status, 'status', t => t.status),
	];

	const viewDetail = (inst: ITransportSummary) => {
		routerContext.navigate(routes.calculateTransportDetailRoute(inst));
	};

	return (
		<Card className='base-transports-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb transports-header'>
					<div className='df-col'>
						<div className='title'>{locContext.transports}</div>
						<div className='comment'>Comment: 005 and 040 not on mySprint</div>
						<div className='comment'>Comment: 005, 010, 020, 023 and 040 not on driver's app</div>
					</div>
					<div className='df-row-ac'></div>
				</div>
				<Divider className='no-margin' />
				<FilterTransports
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<ITransportSummary, ITransportsQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='fileNumber'
					singleIdentifier={locContext.transport}
					onClickRow={inst => viewDetail(inst)}
				/>
			</div>
		</Card>
	);
};
