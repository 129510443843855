import { useEffect, useState } from 'react';
import { useTrigger } from './useTrigger';

export function useApiEffect<TResult>(f: () => Promise<TResult>): [TResult | undefined, boolean, () => void];
export function useApiEffect<TArgs, TResult>(f: (args: TArgs) => Promise<TResult>, args: TArgs): [TResult | undefined, boolean, () => void];
export function useApiEffect<TArgs extends any[], TResult>(f: (...args: TArgs) => Promise<TResult>, ...args: TArgs): [TResult | undefined, boolean, () => void];

export function useApiEffect<TArgs extends any[], TResult>(
	f: (...args: TArgs) => Promise<TResult>,
	...args: TArgs
): [TResult | undefined, boolean, () => void] {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<TResult>();
	const [trigger, reload] = useTrigger();

	useEffect(() => {
		let cancelled = false;
		const loadX = async () => {
			console.log(`load useApiEffect ${f.name}`);
			setIsLoading(true);
			try {
				const r = await f(...args);
				setData(r);
			} catch (error) {
				// no-op
			}
			setIsLoading(false);
		};
		if (cancelled === false) {
			loadX();
		}
		return () => {
			cancelled = true;
		};
		// missing deps handleError -> is a function
		// eslint-disable-next-line
	}, [trigger, f, ...args]);

	return [data, isLoading, reload];
}
