import React from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { Field } from 'formik';
import { DefaultDateInput } from '../fields/DefaultDateInput';
import './SelectDate.scss';

interface IProps<T> extends IFieldGroupProps<T> {

}

export function SelectDate<T>({ ...props }: IProps<T>) {
    const setValue = (fieldProps: any, val: Date | undefined) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, val, true);
        fieldProps.form.setFieldTouched(fieldProps.field.name, true, true);
    }
    return (
        <FieldGroup {...props}>
            <Field name={props.xName} component={(fieldProps: any) =>
                <DefaultDateInput
                    className="field-date-input"
                    value={fieldProps.form.values[fieldProps.field.name]}
                    onValueChanged={(val) => setValue(fieldProps, val)} />} />
        </FieldGroup>
    );
}