export class TokenStore {
	private static STORAGE_KEY: string = 'es-admin-bearer-token';

	static getToken() {
		return window.localStorage.getItem(TokenStore.STORAGE_KEY);
	}

	static setToken(token: string) {
		window.localStorage.setItem(TokenStore.STORAGE_KEY, token);
	}

	static removeToken(): void {
		window.localStorage.removeItem(TokenStore.STORAGE_KEY);
	}
}
