import React, { useState } from 'react';
import { LanguageStore } from '../infrastructure/LanguageStore';
import { empty } from '../infrastructure/Utils';
import { IStrings } from '../localization/IStrings';
import { Strings } from '../localization/Strings';

// documentation: 
// https://github.com/stefalda/react-localization#readme

interface IProps {
    strings: IStrings;
    setLanguage: (lang: 'en' | 'nl' | 'fr') => void;
    getLanguage: () => string;
    formatString: (key: keyof IStrings, ...values: any) => string;
}

const fallback: IProps = {
    strings: Strings,
    setLanguage: empty,
    getLanguage: () => '',
    formatString: () => '',
}

export const LocalizationContext = React.createContext<IProps>(fallback);

export const LocalizationProvider = ({ children }: any) => {
    // eslint-disable-next-line
    const [language, setLanguage] = useState<('en' | 'nl' | 'fr')>('en');
    const overrideSetLanguage = (lang: 'en' | 'nl' | 'fr') => {
        setLanguage(lang);
        Strings.setLanguage(lang);
        LanguageStore.set(lang);
    }
    const getLanguage = () => {
        return Strings.getLanguage();
    }
    function formatString(str: keyof IStrings, ...values: Array<string>) : string {
        return Strings.formatString(Strings[str], ...values).toString();
    }

    return (
        <LocalizationContext.Provider
            value={{
                strings: Strings,
                setLanguage: overrideSetLanguage,
                getLanguage: getLanguage,
                formatString: formatString
            }}>
            {children}
        </LocalizationContext.Provider>
    );
}
