import React, { useState } from 'react';
import { BaseInformation } from '../common/modal/BaseInformation';
import { INotificationDetail, IReadHistory } from '../../gen/ApiClients';
import { Searchbox } from '../common/search/Searchbox';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import './NotificationDetailComponent.scss';
import { toDefaultFormat } from '../../infrastructure/Utils';

interface IProps {
    detail: INotificationDetail;
}
export const NotificationDetailComponent = ({ detail }: IProps) => {
    const locContext = useLocalizationContext();
    const [filtered, setFiltered] = useState<IReadHistory[]>(detail.readHistory ? detail.readHistory : []);
    const handleSearch = (txt: string) => {
        if (txt) {
            const x = detail.readHistory ? detail.readHistory.filter(t => (t.name ? t.name : '').toLowerCase().includes(txt.toString().toLowerCase())) : [];
            setFiltered(x);
        } else {
            setFiltered(detail.readHistory ? detail.readHistory : []);
        }
    }
    const renderContent = () => {
        return (
            <div className="df-col history-container">
                <div className="title">{locContext.readHistory}</div>
                {detail.readHistory && detail.readHistory.length > 0 ?
                    <div className="df-col">
                        <Searchbox className="search-history" placeholder={`${locContext.searchOn}`} onSearch={(txt) => handleSearch(txt)} />
                        {filtered.map(t =>
                            <div>
                                <div className="label">{toDefaultFormat(t.date)}</div>
                                <div className="info">{t.name}</div>
                            </div>)}
                    </div> : <div>{locContext.noOneHasReadThis}</div>}
            </div>
        );
    }
    return (
        <BaseInformation
            title={detail.title}
            description={renderContent()} />
    );
}