import { Redirect, Route, RouteProps } from 'react-router';
import React from 'react';

interface IProps extends RouteProps {
	isAuth: boolean;
	redirectRoute: string;
}

export const ProtectedRoute = ({ isAuth, redirectRoute, ...rest }: IProps) => {
	if (isAuth) {
		return <Route {...rest} />;
	} else {
		return <Redirect to={redirectRoute} />;
	}
};
