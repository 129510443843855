import { UserRole } from 'gen/UserRole';
import { IStrings } from 'localization/IStrings';

export const createUserRoleRecord = (strings: IStrings): Record<UserRole, string> => {
	return {
		Admin: strings.admin,
		Normal: strings.regularUser,
		SuperUser: strings.superUser,
	};
};
