import React from 'react';
import { IStop } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { DefaultDateFormat } from '../../constants';
import moment from 'moment';
import './StopsViewComponent.scss';

interface IProps {
    firstStop: IStop | undefined;
    extraStops: IStop[] | undefined;
    lastStop: IStop | undefined;
}

export const StopsViewComponent = ({ firstStop, extraStops, lastStop, }: IProps) => {
    return (
        <div className="df-col">
            {firstStop ?
                <div className={`df-col`}>
                    <StopViewComponent
                        stop={firstStop} />
                </div>
                : null}
            {extraStops ?
                extraStops.map(t =>
                    <div key={`${t.order}`} className={`df-col`}>
                        <StopViewComponent
                            stop={t} />
                    </div>)
                : null}
            {lastStop ?
                <div className={`df-col`}>
                    <StopViewComponent
                        stop={lastStop} />
                </div>
                : null}
        </div>
    );
}

interface ISingleProps {
    stop: IStop;
}
const StopViewComponent = ({ stop }: ISingleProps) => {
    const locContext = useLocalizationContext();
    const stopName = stop.isFirst ? locContext.firstStop : stop.isLast ? locContext.lastStop : locContext.extraStop + ' ' + stop.extraStopOrder;
    const formatDateSpecifier = (str: string | undefined) => {
        if (str === 'At') {
            return locContext.at;
        } else if (str === 'Before') {
            return locContext.before;
        } else {
            return locContext.after;
        }
    }
    return (
        <div className="df-row jc-sb stop-view">
            <div className="df-col fg1">
                <div className="label">{stopName}</div>
                <div>{moment(stop.date, DefaultDateFormat).format(DefaultDateFormat)} {formatDateSpecifier(stop.dateSpecifier)} {moment(stop.date, DefaultDateFormat).format('HH:mm')}</div>
                <div>{stop.address ? stop.address.company + ' - ' + stop.address.contact : ''}</div>
                <div>{stop.address ? stop.address.formatted : ''}</div>
                {stop.address && stop.address.additionalInfo ? <div>{stop.address.additionalInfo}</div> : null}
                {stop.address && stop.address.comment ? <div>{stop.address.comment}</div> : null}
                {stop.loadOperations ? stop.loadOperations.map(t => <div key={t}>{locContext.load}: {t}</div>) : null}
                {stop.unloadOperations ? stop.unloadOperations.map(t => <div key={t}>{locContext.unload}: {t}</div>) : null}
            </div>
        </div>
    );
}