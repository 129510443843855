import { useRecordContext } from 'context/records/useRecordContext';
import { RuleLogEntryType } from 'gen/RuleLogEntryType';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import {
	IRuleLogEntryDtoQueryResult,
	IRuleLogEntryDto,
	IRulesLogEntriesQueryParams,
	RulesLogEntriesQueryClient,
	RulesLogEntriesQueryParams,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createHeader, createSortableHeader } from '../common/table/TableUtils';
import { FilterRuleLogs } from './FilterRuleLogs';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IRuleLogEntryDto>('timeStamp'),
	sortDirection: 'descending',
};

const DefaultParams: IRulesLogEntriesQueryParams = {
	pageIndex: 1,
	pageSize: 10,
	sortDirection: 'descending',
	sortProperty: nameof<IRuleLogEntryDto>('timeStamp'),
	byUserIds: [],
	timeStampAfter: undefined,
	timeStampBefore: undefined,
};

export const RuleLogsList = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IRuleLogEntryDtoQueryResult>();
	const [params, setParams] = useState<IRulesLogEntriesQueryParams>(DefaultParams);
	const queryClient = useClient(RulesLogEntriesQueryClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const { ruleLogEntryTypeRecord } = useRecordContext();

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(queryClient.query(new RulesLogEntriesQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const headers = [
		createSortableHeader<IRuleLogEntryDto>(strings.date, 'timeStamp', t => toDefaultFormat(t.timeStamp)),
		createHeader<IRuleLogEntryDto>(strings.action, t => ruleLogEntryTypeRecord[t.type as RuleLogEntryType]),
		createSortableHeader<IRuleLogEntryDto>(strings.user, 'byUserEmail', t => t.byUserEmail),
		createHeader<IRuleLogEntryDto>(strings.change, log => (
			<div className='df-col'>
				<div style={{ fontWeight: 'bold' }}>{strings.oldRule}</div>
				<div>{log.hasOldValue ? log.formattedOldValue : 'n/a'}</div>
				<div style={{ fontWeight: 'bold' }}>{strings.newRule}</div>
				<div>{log.hasNewValue ? log.formattedNewValue : 'n/a'}</div>
			</div>
		)),
	];

	return (
		<Card className='orders-card'>
			<div className='df-col stretch-ver'>
				<Divider className='no-margin' />
				<FilterRuleLogs
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<IRuleLogEntryDto, IRulesLogEntriesQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='logEntryId'
					singleIdentifier={strings.action}
				/>
			</div>
		</Card>
	);
};
