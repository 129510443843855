import { useRecordContext } from 'context/records/useRecordContext';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { UserRole, UserRoles } from 'gen/UserRole';
import { executeApiCall } from 'infrastructure/executeApiCall';
import { IStrings } from 'localization/IStrings';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { IEuroSprintersUserDto, UsersCommandClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { IOption } from '../../infrastructure/Models';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { setFieldTouched } from '../common/forms/FormsUtils';
import { SelectField } from '../common/forms/SelectField';
import { showErrorToast } from '../common/toast/ToastNotification';

interface IModel {
	role: string;
}

const createSchema = (strings: IStrings) => {
	return yup.object<IModel>({
		role: yup.string().required(),
	});
};

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	user: IEuroSprintersUserDto;
}

export const PatchUserRoleForm = ({ cancel, confirm, user }: IProps) => {
	const strings = useLocalizationContext();
	const schema = useMemo(() => createSchema(strings), [strings]);
	const commandClient = useClient(UsersCommandClient);
	const { userRoleRecord } = useRecordContext();

	const onClickSubmit = async (props: FormikProps<IModel>, e: any) => {
		setFieldTouched<IModel>('role', props);
		props.setSubmitting(true);
		e.persist();
		e.preventDefault();
		let isValid = await schema.isValid(props.values);
		props.setSubmitting(false);
		if (isValid) {
			handleSubmit(props.values, props);
		}
	};

	const handleSubmit = async (values: IModel, actions: FormikActions<IModel>) => {
		actions.setSubmitting(true);
		const r = await executeApiCall(commandClient.patchRole(user.id!, values.role!), strings);
		if (r.hasError === false) {
			confirm();
		} else {
			showErrorToast(r.errorMessage!);
		}
		actions.setSubmitting(false);
	};

	return (
		<Formik<IModel>
			initialValues={{ role: user.role! }}
			isInitialValid={false}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			{props => (
				<Form style={{ height: '100%' }}>
					<div
						className='df-col pos-rel'
						style={{ height: '100%' }}>
						<div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>{strings.changeUserRole}</div>
						<SelectField<IModel>
							options={UserRoles.map<IOption<string>>(t => ({ key: t, value: t, text: userRoleRecord[t as UserRole] }))}
							label={strings.role}
							xName='role'
						/>
						<div style={{ flexGrow: 1 }}></div>
						<div className='df-row-ac jc-e'>
							<div className='df-row-ac'>
								<TextLinkButton
									onClick={() => cancel()}
									style={{ marginRight: 8 }}>
									{strings.cancel}
								</TextLinkButton>
								<PrimaryButton
									disabled={props.isSubmitting}
									type='submit'
									onClick={e => onClickSubmit(props, e)}>
									{strings.change}
								</PrimaryButton>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
