import { executeApiCall } from 'infrastructure/executeApiCall';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import { CountUnreadMessagesContext } from '../../context/CountUnreadMessagesContext';
import { DisputesQueryParamsContext } from '../../context/DisputesQueryParamsContext';
import { ModalContext } from '../../context/ModalContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { DisputeClient, DisputeQueryParams, IDisputeQueryParams, IDisputeSummary, IDisputeSummaryQueryResult } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useTrigger } from '../../hooks/useTrigger';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createSortableHeader } from '../common/table/TableUtils';
import { showSuccessToast } from '../common/toast/ToastNotification';
import { Dispute } from './Dispute';
import './Disputes.scss';
import { FilterDisputes } from './FilterDisputes';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IDisputeSummary>('createdDate'),
	sortDirection: 'descending',
};

export const Disputes = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IDisputeSummaryQueryResult>();
	const { params, setParams } = useContext(DisputesQueryParamsContext);
	const client = useClient(DisputeClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(WrapperSearchContext).init(
		`${strings.searchOn} ${strings.message.toLowerCase()} & ${strings.servicePartner.toLowerCase()}...`,
		params.searchString,
		(str: string) => {
			setParams({ ...params, searchString: str, pageIndex: 1 });
		}
	);
	const unreadContext = useContext(CountUnreadMessagesContext);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new DisputeQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const headers = [
		createSortableHeader<IDisputeSummary>(strings.createdDate, 'createdDate', t => toDefaultFormat(t.createdDate)),
		createSortableHeader<IDisputeSummary>(strings.servicePartner, 'servicePartnerName', t => t.servicePartnerName),
	];

	const viewDetail = async (inst: IDisputeSummary) => {
		if (!inst.id) {
			return;
		}
		const detail = await tryCatchWithLoading(client.single(inst.id), wrapperLoaderContext.setLoading, strings.serverError);
		if (!detail) {
			return;
		}
		modalContext.open(
			<Dispute
				detail={detail}
				toggleIsRead={async () => {
					await executeApiCall(client.toggleIsRead(inst.id ? inst.id : ''), strings);
					hitTrigger();
					showSuccessToast(inst.isRead === true ? strings.markAsUnread : strings.markedAsRead);
					unreadContext.hitTrigger();
					modalContext.close();
				}}
				cancel={() => modalContext.close()}
			/>,
			true
		);
	};
	const formatRow = (inst: IDisputeSummary) => {
		return inst.isRead === false ? 'font-bold ' : '';
	};

	return (
		<Card className='disputes-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb disputes-header'>
					<div className='title'>{strings.disputes}</div>
				</div>
				<Divider className='no-margin' />
				<FilterDisputes
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<IDisputeSummary, IDisputeQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.disputeSingular}
					onClickRow={inst => viewDetail(inst)}
					formatRow={inst => formatRow(inst)}
				/>
			</div>
		</Card>
	);
};
