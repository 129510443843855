import React from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../infrastructure/Utils';
import './Dispute.scss';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { IDisputeDetail } from '../../gen/ApiClients';

interface IProps {
    detail: IDisputeDetail
    toggleIsRead: () => Promise<any>;
    cancel: () => void;
}
export const Dispute = ({ detail, toggleIsRead, cancel }: IProps) => {
    const locContext = useLocalizationContext();

    const renderContent = () => {
        return (
            <div className="df-col fd-container stretch-ver">
                <div className="df-col fd-inner">
                    <div className="label">{locContext.date}</div>
                    <div className="info">{toDefaultFormat(detail.createdDate)}</div>
                    <div className="label">{locContext.servicePartner}</div>
                    <div className="info">{detail.servicePartnerName}</div>
                    <div className="label">{locContext.message}</div>
                    <div className="info">{detail.message}</div>
                </div>
            </div>
        );
    }

    return (
        <BaseConfirmation
            title={locContext.disputeSingular}
            description={renderContent()}
            confirmText={detail.isRead === false ? locContext.markAsRead : locContext.markAsUnread}
            confirm={async () => await toggleIsRead()}
            cancel={() => cancel()} />
    );
}