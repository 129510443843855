import { IHeader } from "../../../infrastructure/Models";

export function createSortableHeader<T>(text: string, key: keyof T, evaluate: (inst: T) => string | JSX.Element | undefined, disableClick: boolean = false): IHeader<T> {
    return { text: text, key: key.toString(), evaluate: evaluate, sortProperty: key, disableClick: disableClick};
}
export function createHeader<T>(text: string, evaluate: (inst: T) => string | JSX.Element | undefined, disableClick: boolean = false): IHeader<T> {
    return { text: text, key: text, evaluate: evaluate, disableClick: disableClick};
}
export function createEmptyHeader<T>(key: string, evaluate: (inst: T) => string | JSX.Element | undefined, noPadding?: boolean, disableClick: boolean = false): IHeader<T> {
    return { text: '', key: key, evaluate: evaluate, noPadding: noPadding ? noPadding : false, disableClick: disableClick};
}