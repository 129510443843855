import { useContext, useEffect, useState } from 'react';
import { Card, Checkbox, Divider } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import {
	IExtraTimeMarginRuleDto,
	IExtraTimeMarginRuleDtoQueryResult,
	IRulesQueryParams,
	RulesCommandClient,
	RulesQueryClient,
	RulesQueryParams,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useTrigger } from '../../hooks/useTrigger';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { IconButton } from '../common/buttons/IconButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createEmptyHeader, createHeader, createSortableHeader } from '../common/table/TableUtils';
import { showErrorToast, showSuccessToast } from '../common/toast/ToastNotification';
import { FilterRules } from './FilterRules';
import { RuleForm } from './RuleForm';
import React from 'react';
import { LoadOrUnloadActionType } from '../../gen/LoadOrUnloadActionType';
import { executeApiCall } from 'infrastructure/executeApiCall';
import { useRecordContext } from 'context/records/useRecordContext';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IExtraTimeMarginRuleDto>('created'),
	sortDirection: 'descending',
};

const DefaultParams: IRulesQueryParams = {
	pageIndex: 1,
	pageSize: 10,
	sortDirection: 'descending',
	sortProperty: nameof<IExtraTimeMarginRuleDto>('created'),
	isActive: undefined,
	searchString: '',
	validityAfterAfterDate: undefined,
	validityAfterBeforeDate: undefined,
	validityBeforeAfterDate: undefined,
	validityBeforeBeforeDate: undefined,
};

export const RulesList = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IExtraTimeMarginRuleDtoQueryResult>();
	const [params, setParams] = useState<IRulesQueryParams>(DefaultParams);
	const queryClient = useClient(RulesQueryClient);
	const commandClient = useClient(RulesCommandClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	const { loadOrUnloadActionTypeRecord } = useRecordContext();
	useContext(WrapperSearchContext).init(`${strings.search}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(queryClient.query(new RulesQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const headers = [
		createHeader<IExtraTimeMarginRuleDto>(strings.isActive, t => renderToggle(t)),
		createSortableHeader<IExtraTimeMarginRuleDto>(strings.validityFrom, 'validityAfter', t => toDefaultFormat(t.validityAfter)),
		createSortableHeader<IExtraTimeMarginRuleDto>(strings.validityUntil, 'validityBefore', t => toDefaultFormat(t.validityBefore)),
		createSortableHeader<IExtraTimeMarginRuleDto>(strings.postalCode, 'zipCode', t => (t.hasRegion === true ? '' : t.zipCode)),
		createSortableHeader<IExtraTimeMarginRuleDto>(strings.country, 'countryCode', t => (t.hasRegion === true ? '' : t.countryCode)),
		createHeader<IExtraTimeMarginRuleDto>(strings.region, t => (t.hasRegion === false ? '' : t.region?.name ?? '')),
		createHeader<IExtraTimeMarginRuleDto>(strings.timeFrame, t => `${t.timeRangeAfter}-${t.timeRangeBefore}`),
		createHeader<IExtraTimeMarginRuleDto>(strings.action, t => loadOrUnloadActionTypeRecord[t.loadOrUnloadAction as LoadOrUnloadActionType]),
		createSortableHeader<IExtraTimeMarginRuleDto>(strings.timeMarginInMinutes, 'timeMarginInMinutes', t => `${t.timeMarginInMinutes} min.`),
		createEmptyHeader<IExtraTimeMarginRuleDto>('actions', t => renderActions(t), false, true),
	];

	const renderToggle = (rule: IExtraTimeMarginRuleDto) => {
		return (
			<Checkbox
				toggle
				checked={rule.isActive}
				onClick={() => onToggleIsActive(rule)}
			/>
		);
	};

	const onToggleIsActive = async (rule: IExtraTimeMarginRuleDto) => {
		modalContext.open(
			<BaseConfirmation
				title={rule.isActive ? strings.deactivateRule : strings.activateRule}
				description={rule.isActive ? strings.deactiveRuleConfirmation : strings.activateRuleConfirmation}
				cancel={() => modalContext.close()}
				confirmText={rule.isActive ? strings.yesCommaDeactivate : strings.yesCommaActivate}
				confirm={async () => {
					const r = rule.isActive
						? await executeApiCall(commandClient.deactivate(rule.id!), strings)
						: await executeApiCall(commandClient.activate(rule.id!), strings);
					if (r.hasError) {
						showErrorToast(r.error!);
					}
					hitTrigger();
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const renderActions = (rule: IExtraTimeMarginRuleDto) => {
		return (
			<div className='df-row-ac'>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => deleteRule(rule)}
				/>
				<IconButton
					color='gray'
					size='large'
					className='edit-button'
					icon={['far', 'edit']}
					onClick={() => editRule(rule)}
				/>
			</div>
		);
	};

	const deleteRule = (inst: IExtraTimeMarginRuleDto) => {
		modalContext.open(
			<BaseConfirmation
				title={strings.deleteRuleQuestion}
				description={strings.confirmDeleteRule}
				cancel={() => modalContext.close()}
				confirmText={strings.yesDelete}
				confirm={async () => {
					await executeApiCall(commandClient.delete(inst.id ? inst.id : ''), strings);
					hitTrigger();
					showSuccessToast(strings.ruleDeleted);
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const editRule = (inst: IExtraTimeMarginRuleDto) => {
		modalContext.open(
			<RuleForm
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
				rule={inst}
			/>,
			false
		);
	};

	const addRule = () => {
		modalContext.open(
			<RuleForm
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			false
		);
	};

	return (
		<Card className='orders-card'>
			<div className='df-col stretch-ver'>
				<div
					className='df-row-ac jc-sb'
					style={{ margin: 16 }}>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							onClick={() => addRule()}>
							+ {strings.addRule}
						</PrimaryButton>
					</div>
				</div>
				<Divider className='no-margin' />
				<FilterRules
					onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
					params={params}
				/>
				<BaseTableSortAndPage<IExtraTimeMarginRuleDto, IRulesQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.extraTimeMarginRules}
					formatRow={t => (t.isActive === false ? 'font-light ' : '')}
				/>
			</div>
		</Card>
	);
};
