import React, { useMemo } from 'react';
import { FilterDataClient, IStringNamedEntity } from '../gen/ApiClients';
import { useApiEffect } from '../hooks/useApiEffect';
import { useClient } from '../hooks/useClient';

interface IProps {
	allDepartments: string[];
	allSubDepartments: string[];
	allClients: IStringNamedEntity[];
	allServicePartners: IStringNamedEntity[];
	isLoaded: boolean;
}

const fallbackProps: IProps = {
	allDepartments: [],
	allSubDepartments: [],
	allClients: [],
	allServicePartners: [],
	isLoaded: false,
};

export const FilterDataContext = React.createContext<IProps>(fallbackProps);

export const FilterDataProvider = ({ children }: any) => {
	const client = useClient(FilterDataClient);
	const [filterData] = useApiEffect(client.data);
	const servicePartners = useMemo(() => filterData?.servicePartners ?? [], [filterData]);
	const clients = useMemo(() => filterData?.clients ?? [], [filterData]);
	const depts = useMemo(() => filterData?.departments ?? [], [filterData]);
	const subDepts = useMemo(() => filterData?.subDepartments ?? [], [filterData]);

	return (
		<FilterDataContext.Provider
			value={{
				allServicePartners: servicePartners,
				allClients: clients,
				allDepartments: depts,
				allSubDepartments: subDepts,
				isLoaded: filterData !== undefined,
			}}>
			{children}
		</FilterDataContext.Provider>
	);
};
