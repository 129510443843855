import React from 'react';
import { IconButton } from '../buttons/IconButton';
import './CustomDropdown.scss';

interface IProps {
    text: string;
    isOpen: boolean;
    clickTrigger: () => void;
    selected: boolean;
    onClear: () => void;
    clearable: boolean;
    formatSelected: boolean;
    small: boolean;
}

export const DropDownTrigger = ({ text, isOpen, clickTrigger, onClear, selected, clearable, formatSelected, small }: IProps) => {
    return (
        <div className={`df-row-ac dd-trigger${isOpen ? ' is-open' : ''}${selected ? ' selected' : ''}${selected && formatSelected ? ' format-selected' : ''}`}>
            <div className={`label-text ${small ? ' small' : ''}`} onClick={clickTrigger}>{text}</div>
            {selected && clearable ?
                <IconButton size="large" className="ddt-icon" icon={['fas', 'times']} onClick={onClear} />
                :
                <IconButton className="ddt-icon" icon={['fas', isOpen ? 'chevron-up' : 'chevron-down']} onClick={clickTrigger} />
            }
        </div>
    );
}