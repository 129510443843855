import { showErrorToast } from 'components/common/toast/ToastNotification';
import { IStrings } from 'localization/IStrings';
import { reject } from 'q';

export async function executeApiCall<T>(fnc: Promise<T>, strings: IStrings): Promise<T> {
	try {
		return await fnc;
	} catch (error) {
		const errorMessage = matchError(error, strings);
		showErrorToast(errorMessage);
		return reject();
	}
}

const matchError = (error: any, strings: IStrings) => {
	const status = error?.response?.status ?? undefined;
	if (error === undefined) {
		return '';
	} else if (status) {
		switch (status) {
			case 401:
				return strings.error401;
			case 403:
				return strings.error403;
			case 500:
				return strings.error500;
			default:
				return strings.error500;
		}
	} else if (error.toString() === 'Error: Network Error') {
		return strings.errorNoNetwork;
	} else {
		return strings.error500;
	}
};
