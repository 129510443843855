import React from 'react';
import loader from './loader.gif';

export const Loader = () => {
    var divStyle: React.CSSProperties = {
        maxHeight: `100px`,
        maxWidth: `100px`,
        marginTop: '10px',
        marginBottom: '10px',
    }
    return (
        <img style={divStyle} src={loader} alt="loading..." />
    );
}

