import { executeApiCall } from 'infrastructure/executeApiCall';
import { useContext, useEffect, useState } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import {
	DeleteRegionRequest,
	IRegion,
	IRegionDto,
	IRegionDtoQueryResult,
	IRegionsQueryParams,
	RegionsCommandClient,
	RegionsQueryClient,
	RegionsQueryParams,
} from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useTrigger } from '../../hooks/useTrigger';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, tryCatchWithLoading } from '../../infrastructure/Utils';
import { IconButton } from '../common/buttons/IconButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createEmptyHeader, createHeader } from '../common/table/TableUtils';
import React from 'react';
import { CreateRegionRequestForm } from './CreateRegionRequestForm';
import { PatchRegionRequestForm } from './PatchRegionRequestForm';
import { RegionPostalCodeDetailsDialog } from './RegionPostalCodeDetailsDialog';

const fallbackSortProps: ISortProps = {
	sortProperty: nameof<IRegionDto>('name'),
	sortDirection: 'ascending',
};

const DefaultParams: IRegionsQueryParams = {
	pageIndex: 1,
	pageSize: 10,
	sortDirection: 'descending',
	sortProperty: nameof<IRegionDto>('name'),
	searchString: '',
};

interface IRegionPostalCodeTableColumnProps {
	item: IRegionDto;
}

const RegionPostalCodeTableColumn = ({ item }: IRegionPostalCodeTableColumnProps) => {
	const filtered = React.useMemo(() => item.postalCodes?.slice(0, 3) ?? [], [item]);

	return (
		<div className='df-col'>
			{filtered.map(t => (
				<div key={t.postalCode!.toString()}>{`- ${t.postalCode}: ${t.placeName}`}</div>
			))}
			{item.countPostalCodes! > 3 && <div>{`Nog ${item.countPostalCodes! - 3} meer...`}</div>}
		</div>
	);
};

export const RegionsList = () => {
	const strings = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IRegionDtoQueryResult>();
	const [params, setParams] = useState<IRegionsQueryParams>(DefaultParams);
	const queryClient = useClient(RegionsQueryClient);
	const commandClient = useClient(RegionsCommandClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const modalContext = useContext(ModalContext);
	const [trigger, hitTrigger] = useTrigger();
	useContext(WrapperSearchContext).init(`${strings.search}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(queryClient.query(new RegionsQueryParams(params)), wrapperLoaderContext.setLoading, strings.serverError));
	};

	const headers = [
		createHeader<IRegionDto>(`Naam`, t => t.name),
		createHeader<IRegionDto>(`Aantal`, t => t.countPostalCodes?.toString()),
		createHeader<IRegion>(`Postcodes`, t => <RegionPostalCodeTableColumn item={t} />),
		createEmptyHeader<IRegionDto>('actions', t => renderActions(t), false, true),
	];

	const renderActions = (region: IRegionDto) => {
		return (
			<div className='df-row-ac'>
				<IconButton
					color='gray'
					size='large'
					icon={['far', 'trash-alt']}
					onClick={() => deleteRegion(region)}
				/>
				<IconButton
					color='gray'
					size='large'
					className='edit-button'
					icon={['far', 'edit']}
					onClick={() => editRegion(region)}
				/>
			</div>
		);
	};

	const deleteRegion = (inst: IRegionDto) => {
		modalContext.open(
			<BaseConfirmation
				title={`Regio verwijderen`}
				description={`Wil je region ${inst.name} verwijderen?`}
				cancel={() => modalContext.close()}
				confirmText={strings.yesDelete}
				confirm={async () => {
					await executeApiCall(commandClient.delete(new DeleteRegionRequest({ id: inst.id })), strings);
					hitTrigger();
					modalContext.close();
				}}
			/>,
			false
		);
	};

	const editRegion = (inst: IRegionDto) => {
		modalContext.open(
			<PatchRegionRequestForm
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
				region={inst}
			/>,
			false
		);
	};

	const addRegion = () => {
		modalContext.open(
			<CreateRegionRequestForm
				cancel={() => modalContext.close()}
				confirm={() => {
					modalContext.close();
					hitTrigger();
				}}
			/>,
			false
		);
	};

	const onViewDetails = (item: IRegionDto) => {
		modalContext.open(<RegionPostalCodeDetailsDialog item={item} />);
	};

	return (
		<Card className='orders-card'>
			<div className='df-col stretch-ver'>
				<div
					className='df-row-ac jc-sb'
					style={{ margin: 16 }}>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							onClick={() => addRegion()}>
							+ {strings.addRegion}
						</PrimaryButton>
					</div>
				</div>
				<Divider className='no-margin' />
				<BaseTableSortAndPage<IRegionDto, IRegionsQueryParams>
					params={params}
					onSortOrPage={t => setParams(t)}
					fallbackSortProps={fallbackSortProps}
					queryResult={queryResult}
					canSort={true}
					headers={headers}
					uniqueIdentifier='id'
					singleIdentifier={strings.region}
					onClickRow={item => onViewDetails(item)}
					// formatRow={t => (t.isActive === false ? 'font-light ' : '')}
				/>
			</div>
		</Card>
	);
};
