import { PrimaryButton } from 'components/common/buttons/PrimaryButton';
import { FormDialog } from 'components/common/forms/FormDialog';
import { TextInputField } from 'components/common/forms/TextInputField';
import { Form, Formik, FormikActions } from 'formik';
import { AuthClient, ForgotPasswordRequest, IForgotPasswordRequest } from 'gen/ApiClients';
import { useClient } from 'hooks/useClient';
import { useLocalizationContext } from 'hooks/useLocalizationContext';
import { IStrings } from 'localization/IStrings';
import React, { useState, useMemo } from 'react';
import * as yup from 'yup';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';

const createSchema = (strings: IStrings) => {
	return yup.object<IForgotPasswordRequest>({
		email: yup.string().email().required(),
	});
};

export const ForgotPasswordPage = () => {
	const client = useClient<AuthClient>(AuthClient);
	const strings = useLocalizationContext();
	const [hasSent, setHasSent] = useState<boolean>(false);
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (values: IForgotPasswordRequest, helpers: FormikActions<IForgotPasswordRequest>) => {
		const r = await client.forgotPassword(new ForgotPasswordRequest(values));
		if (r.hasError === false) {
			setHasSent(true);
		}
	};

	return (
		<EmptyPageWithLogo
			title={strings.forgotPasswordTitle}
			subTitle={strings.forgotPasswordSubtitle}>
			<FormDialog style={{ width: 600 }}>
				<Formik<IForgotPasswordRequest>
					initialValues={{ email: '' }}
					validationSchema={schema}
					onSubmit={handleSubmit}>
					{props => (
						<Form
							autoComplete='off'
							className='df-col'
							style={{ minWidth: '400px' }}>
							{hasSent === false && (
								<>
									<TextInputField<IForgotPasswordRequest>
										xName='email'
										label={strings.email}
									/>
									<PrimaryButton
										disabled={props.isSubmitting}
										type='submit'
										style={{ marginTop: 30 }}>
										{strings.resetPassword}
									</PrimaryButton>
								</>
							)}
							{hasSent === true && (
								<div className='df-row-ac'>
									<div>{strings.resetPasswordEmailSent}</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			</FormDialog>
		</EmptyPageWithLogo>
	);
};
