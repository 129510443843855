import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';

interface IProps extends React.HTMLProps<HTMLDivElement> {
}

export const CenterEveryting = ({ children, ...rest }: IProps) => {
    return (
        <div className={useCombineClassNames('df-col-ac stretch-hor stretch-ver', rest)}>
            <div className="df-row-ac stretch-hor stretch-ver jc-c">
                {children}
            </div>
        </div>
    );
}