import { useRouterContext } from 'context/useRouterContext';
import React, { useContext, useMemo } from 'react';
import { Route } from 'react-router';
import { NavLink, Redirect, Switch } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { AuthContext } from '../context/AuthContext';
import { CountUnreadMessagesContext, CountUnreadMessagesProvider } from '../context/CountUnreadMessagesContext';
import { DisputesQueryParamsProvider } from '../context/DisputesQueryParamsContext';
import { FilterDataProvider } from '../context/FilterDataContext';
import { ModalProvider } from '../context/ModalContext';
import { NotificationsQueryParamsProvider } from '../context/NotificationsQueryParamsContext';
import { OrdersQueryParamsProvider } from '../context/OrdersQueryParamsContext';
import { TransportsQueryParamsProvider } from '../context/TransportsQueryParamsContext';
import { WrapperFullScreenLoadContext, WrapperFullScreenLoadProvider } from '../context/WrapperFullScreenLoadContext';
import { WrapperSearchProvider } from '../context/WrapperSearchTextContext';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import * as routes from '../routes';
import { FullScreenLoader } from './common/loaders/FullScreenLoader';
import { Logo } from './common/Logo';
import { showSuccessToast } from './common/toast/ToastNotification';
import { Dashboard } from './Dashboard';
import { Disputes } from './disputes/Disputes';
import { Notifications } from './notifications/Notifications';
import { Orders } from './orders/Orders';
import { RulesList } from './rules/RulesList';
import { RuleLogsList } from './rulesLogs/RuleLogsList';
import { Settings } from './settings/Settings';
import { Transport } from './transports/Transport';
import { Transports } from './transports/Transports';
import { useLoggedInUser } from './users/useLoggedInUser';
import { UsersList } from './users/UsersList';
import { UsersQueryProvider } from './users/UsersQueryProvider';
import { useUserIsSuperUserOrHasFunctionClaim } from './users/useUserIsSuperOrHasFunctionalClaim';
import './Wrapper.scss';
import { WrapperSearchbox } from './WrapperSearchbox';
import { useUserHasFunctionalClaim } from './users/useUserHasFunctionalClaim';
import { RegionsList } from './regions/RegionsList';

export const Wrapper = () => {
	return (
		<FilterDataProvider>
			<CountUnreadMessagesProvider>
				<NotificationsQueryParamsProvider>
					<OrdersQueryParamsProvider>
						<DisputesQueryParamsProvider>
							<TransportsQueryParamsProvider>
								<UsersQueryProvider>
									<ModalProvider>
										<WrapperSearchProvider>
											<WrapperFullScreenLoadProvider>
												<InnerObject />
											</WrapperFullScreenLoadProvider>
										</WrapperSearchProvider>
									</ModalProvider>
								</UsersQueryProvider>
							</TransportsQueryParamsProvider>
						</DisputesQueryParamsProvider>
					</OrdersQueryParamsProvider>
				</NotificationsQueryParamsProvider>
			</CountUnreadMessagesProvider>
		</FilterDataProvider>
	);
};

const InnerObject = () => {
	const locContext = useLocalizationContext();
	const authContext = useContext(AuthContext);
	const loaderContext = useContext(WrapperFullScreenLoadContext);
	const countMessagesContext = useContext(CountUnreadMessagesContext);
	const routerContext = useRouterContext();
	const canViewRules = useUserHasFunctionalClaim('ViewRules');
	const canViewRegions = useUserHasFunctionalClaim('ViewRegions');
	const canViewNotifications = useUserIsSuperUserOrHasFunctionClaim('ViewNotifications');
	const canViewOrders = useUserIsSuperUserOrHasFunctionClaim('ViewOrders');
	const canViewTransports = useUserIsSuperUserOrHasFunctionClaim('ViewTransports');
	const canViewDisputes = useUserIsSuperUserOrHasFunctionClaim('ViewDisputes');
	const user = useLoggedInUser();
	const canViewUsers = useMemo(() => user.isAdminOrSuperUser, [user]);

	const renderOrders = () => {
		if (countMessagesContext.countOrders > 0) {
			return (
				<div className='df-row'>
					{locContext.orders}{' '}
					<Label
						circular
						color='red'
						className='c-label'>
						{countMessagesContext.countOrders}
					</Label>
				</div>
			);
		} else {
			return locContext.orders;
		}
	};
	const renderDisputes = () => {
		if (countMessagesContext.countDisputes > 0) {
			return (
				<div className='df-row'>
					{locContext.disputes}{' '}
					<Label
						circular
						color='red'
						className='c-label'>
						{countMessagesContext.countDisputes}
					</Label>
				</div>
			);
		} else {
			return locContext.disputes;
		}
	};

	const onLogout = () => {
		authContext.logout();
		routerContext.navigate(routes.LoginRoute);
		showSuccessToast(locContext.successfullyLoggedOut);
	};

	return (
		<div className='df-row container'>
			<div className='side-nav df-col jc-sb sticky-at-top'>
				<div className='df-col'>
					<Logo />
					<div className='nav-links df-col'>
						{canViewNotifications && (
							<NavLink
								className='upper-nav-link'
								to={routes.NotificationsRoute}>
								{locContext.notifications}
							</NavLink>
						)}
						{canViewOrders && (
							<NavLink
								className='upper-nav-link'
								to={routes.OrdersRoute}>
								{renderOrders()}
							</NavLink>
						)}
						{canViewDisputes && (
							<NavLink
								className='upper-nav-link'
								to={routes.DisputesRoute}>
								{renderDisputes()}
							</NavLink>
						)}
						{canViewTransports && (
							<NavLink
								className='upper-nav-link'
								to={routes.TransportsRoute}>
								{locContext.transports}
							</NavLink>
						)}
						{canViewRules && (
							<>
								<NavLink
									className='upper-nav-link'
									to={routes.RulesRoute}>
									{locContext.extraTimeMarginRules}
								</NavLink>
								<NavLink
									className='upper-nav-link'
									to={routes.RuleLogsRoute}>
									{locContext.logs}
								</NavLink>
							</>
						)}
						{canViewRegions && (
							<>
								<NavLink
									className='upper-nav-link'
									to={routes.RegionsRoute}>
									{locContext.regions}
								</NavLink>
							</>
						)}
						{canViewUsers && (
							<NavLink
								className='upper-nav-link'
								to={routes.UsersRoute}>
								{locContext.users}
							</NavLink>
						)}
					</div>
				</div>
				<div className='nav-links df-col'>
					<div className='company-name'>Euro-Sprinters</div>
					<NavLink
						className='lower-nav-link'
						to={routes.SettingsRoute}>
						{locContext.settings}
					</NavLink>
					{/* eslint-disable-next-line */}
					<a
						className='lower-nav-link'
						onClick={() => onLogout()}>
						{locContext.logOut}
					</a>
				</div>
			</div>
			<div className='body-wrapper df-col pos-rel'>
				{loaderContext.isLoading ? <FullScreenLoader /> : null}
				<div className='top-bar df-row-ac jc-sb sticky-at-top'>
					<WrapperSearchbox className='search-anything'></WrapperSearchbox>
					<div className='df-row-ac'>
						<div className='user-text'>{authContext.user ? authContext.user.firstName : ''}</div>
					</div>
				</div>
				<div className='body-content'>
					<Switch>
						<Route
							path={routes.TransportDetailRoute}
							component={Transport}
						/>
						<Route
							path={routes.TransportsRoute}
							component={Transports}
						/>
						<Route
							path={routes.SettingsRoute}
							component={Settings}
						/>
						<Route
							path={routes.NotificationsRoute}
							component={Notifications}
						/>
						<Route
							path={routes.OrdersRoute}
							component={Orders}
						/>
						<Route
							path={routes.DisputesRoute}
							component={Disputes}
						/>
						<Route
							path={routes.RulesRoute}
							component={RulesList}
						/>
						<Route
							path={routes.RegionsRoute}
							component={RegionsList}
						/>
						<Route
							path={routes.UsersRoute}
							component={UsersList}
						/>
						<Route
							path={routes.RuleLogsRoute}
							component={RuleLogsList}
						/>
						<Route
							path={routes.DashboardRoute}
							component={Dashboard}
						/>
						<Redirect
							path='/'
							to={routes.DashboardRoute}
						/>
					</Switch>
				</div>
			</div>
		</div>
	);
};
