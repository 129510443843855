import React, { useContext } from 'react';
import { ITransportsQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterDataContext } from '../../context/FilterDataContext';
import { FilterBar } from '../common/filter/FilterBar';
import { StringListFilterItem } from '../common/filter/StringListFilterItem';
import { ListFilterItem } from '../common/filter/ListFilterItem';
import { IOption } from '../../infrastructure/Models';
import { DateFilterItem } from '../common/filter/DateFilterItem';

interface IProps {
    params: ITransportsQueryParams;
    onFilter: (params: ITransportsQueryParams) => void;
}

function isFiltered(pars: ITransportsQueryParams) {
    if (!pars) {
        return false;
    }
    if (pars.statuses && pars.statuses.length > 0) {
        return true;
    }
    if (pars.fileAfterDate || pars.fileBeforeDate) {
        return true;
    }
    if (pars.firstStopAfterDate || pars.firstStopBeforeDate) {
        return true;
    }
    if (pars.departments && pars.departments.length > 0) {
        return true;
    }
    if (pars.subDepartments && pars.subDepartments.length > 0) {
        return true;
    }
    if (pars.clientIds && pars.clientIds.length > 0) {
        return true;
    }
    if (pars.servicePartnerIds && pars.servicePartnerIds.length > 0) {
        return true;
    }
    return false;
}

const statuses: string[] = ['005', '010', '020', '021', '022', '023', '024', '025', '026', '027', '028', '030', '040', '090', '100'];

export const FilterTransports = ({ params, onFilter }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, statuses: [], departments: [], subDepartments: [], servicePartnerIds: [], clientIds: [], fileAfterDate: undefined, fileBeforeDate: undefined, firstStopAfterDate: undefined, firstStopBeforeDate: undefined });
    }

    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac">
                <StringListFilterItem
                    icon={['fas', 'info']}
                    label={locContext.status}
                    options={statuses}
                    selection={params.statuses ? params.statuses : []}
                    onFilter={(val) => onFilter({ ...params, statuses: val })} />
                <StringListFilterItem
                    icon={['fas', 'car-side']}
                    label={locContext.department}
                    options={context.allDepartments}
                    selection={params.departments ? params.departments : []}
                    onFilter={(val) => onFilter({ ...params, departments: val })} />
                <StringListFilterItem
                    icon={['fas', 'receipt']}
                    label={locContext.subDepartment}
                    options={context.allSubDepartments}
                    selection={params.subDepartments ? params.subDepartments : []}
                    onFilter={(val) => onFilter({ ...params, subDepartments: val })} />
                <ListFilterItem<string>
                    icon={['fas', 'users']}
                    label={locContext.client}
                    options={context.allClients.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '' }))}
                    selection={params.clientIds ? params.clientIds : []}
                    onFilter={(val) => onFilter({ ...params, clientIds: val })} />
                <ListFilterItem<string>
                    icon={['fas', 'users']}
                    label={locContext.servicePartner}
                    options={context.allServicePartners.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '' }))}
                    selection={params.servicePartnerIds ? params.servicePartnerIds : []}
                    onFilter={(val) => onFilter({ ...params, servicePartnerIds: val })} />
                <DateFilterItem
                    undefinedLabel={locContext.fileDate}
                    afterDate={params.fileAfterDate}
                    beforeDate={params.fileBeforeDate}
                    onFilter={(val) => onFilter({ ...params, fileAfterDate: val.after, fileBeforeDate: val.before })} />
                <DateFilterItem
                    undefinedLabel={locContext.firstStopDate}
                    afterDate={params.firstStopAfterDate}
                    beforeDate={params.firstStopBeforeDate}
                    onFilter={(val) => onFilter({ ...params, firstStopAfterDate: val.after, firstStopBeforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}
