import React from 'react';
import './ToastNotification.scss';
import { toast } from 'react-toastify';

interface IProps {
    message: string;
}

export const showSuccessToast = (message: string) => {
    showToast(message, "success");
}

export const showErrorToast = (message: string) => {
    showToast(message, "error");
}

const showToast = (message: string, type: 'success' | 'warning' | 'error') => {
    toast(<ToastNotification message={message} />, { type: type });
}

export const ToastNotification = ({ message }: IProps) => {
    return (
        <div className="toast-notification">
            {message}
        </div>
    );
}