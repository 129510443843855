import React, {  } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { Formik, Form, FormikActions } from 'formik';
import { IAutoGenerateModel } from '../../gen/ApiClients';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { ToggleCheckbox } from '../common/ToggleCheckbox';
import { SelectDate } from '../common/forms/SelectDate';
import { setFieldValue } from '../common/forms/FormsUtils';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import * as yup from 'yup';
import './NotificationForm.scss';
import moment from 'moment';

interface IProps {
    onCancel: () => void;
    onSubmit: (model: IAutoGenerateModel) => Promise<any>;
}
export const AutoGenerateForm = ({ onCancel, onSubmit }: IProps) => {
    const locContext = useLocalizationContext();

    const initValues: IAutoGenerateModel = {
        forCmr: true,
        forGreenCard: true,
        cmrExpiredAfterDate: moment(new Date()).add(14, 'days').toDate(),
        greenCardExpiredAfterDate: moment(new Date()).add(14, 'days').toDate(),
    }

    const validateSchema = yup.object<IAutoGenerateModel>().shape<IAutoGenerateModel>({
    });

    const handleSubmit = async (values: IAutoGenerateModel, actions: FormikActions<IAutoGenerateModel>) => {
        actions.setSubmitting(true);
        await onSubmit(values);
        actions.setSubmitting(false);
    }

    return (
        <div>
            <Formik<IAutoGenerateModel>
                initialValues={initValues}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="df-col not-form-container pos-rel">
                            <div className="not-title">{locContext.autoGenerateFormTitle}</div>

                            <div className="df-row-ac jc-sb option">
                                <div className="option-text">{locContext.forCmr}?</div>
                                <ToggleCheckbox externalProps={{ value: props.values.forCmr ? props.values.forCmr : false, setValue: (val) => setFieldValue<IAutoGenerateModel>('forCmr', val, props) }} />
                            </div>
                            {props.values.forCmr === true ?
                                <SelectDate<IAutoGenerateModel> label={locContext.cmrExpiredAfterDate} xName="cmrExpiredAfterDate" />
                                : null}
                            <div className="df-row-ac jc-sb option">
                                <div className="option-text">{locContext.forGreenCard}?</div>
                                <ToggleCheckbox externalProps={{ value: props.values.forGreenCard ? props.values.forGreenCard : false, setValue: (val) => setFieldValue<IAutoGenerateModel>('forGreenCard', val, props) }} />
                            </div>
                            {props.values.forGreenCard === true ?
                                <SelectDate<IAutoGenerateModel> label={locContext.greenCardExpiredAfterDate} xName="greenCardExpiredAfterDate" />
                                : null}
                            <div className="df-row-ac jc-e">
                                <div className="df-row-ac">
                                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                    <PrimaryButton disabled={props.isSubmitting} type="submit">{locContext.generate}</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}