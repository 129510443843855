import { Form, Formik, FormikActions } from 'formik';
import { executeApiCall } from 'infrastructure/executeApiCall';
import { useMemo } from 'react';
import * as yup from 'yup';
import { CreateRegionRequest, ICreateRegionRequest, RegionsCommandClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { IStringsWithFormat, useLocalizationContext } from '../../hooks/useLocalizationContext';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { TextInputField } from '../common/forms/TextInputField';
import { showErrorToast } from '../common/toast/ToastNotification';
import React from 'react';
import { TextareaField } from 'components/common/forms/TextareaField';

const createSchema = (strings: IStringsWithFormat) => {
	return yup.object<ICreateRegionRequest>({
		name: yup.string().required(`Naam is verplicht`),
		csvZipCodes: yup.string().required(),
	});
};

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
}

export const CreateRegionRequestForm = ({ cancel, confirm }: IProps) => {
	const strings = useLocalizationContext();
	const commandClient = useClient(RegionsCommandClient);
	const schema = useMemo(() => createSchema(strings), [strings]);

	const handleSubmit = async (values: ICreateRegionRequest, actions: FormikActions<ICreateRegionRequest>) => {
		actions.setSubmitting(false);
		const r = await executeApiCall(commandClient.add(new CreateRegionRequest(values)), strings);
		if (r.hasError === false) {
			confirm();
		} else {
			showErrorToast(r.error!);
		}
		actions.setSubmitting(false);
	};

	return (
		<Formik<ICreateRegionRequest>
			initialValues={{
				name: '',
				csvZipCodes: '',
			}}
			isInitialValid={false}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<div className='df-col pos-rel'>
						<div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>{strings.addRegion}</div>
						<TextInputField<ICreateRegionRequest>
							label={`Naam`}
							xName='name'
						/>
						<TextareaField<ICreateRegionRequest>
							label={`Postcodes\n(op een nieuwe lijn of gescheiden door een ';' teken)`}
							xName='csvZipCodes'
							placeholder={`scheiding dmw ';'-teken. Bvb. 2000;2200`}
						/>
						<div className='df-row-ac jc-e'>
							<div className='df-row-ac'>
								<TextLinkButton
									className='cancel-button'
									onClick={() => cancel()}
									style={{ marginRight: 4 }}>
									{strings.cancel}
								</TextLinkButton>
								<PrimaryButton
									disabled={props.isSubmitting}
									type='submit'>
									{strings.add}
								</PrimaryButton>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
