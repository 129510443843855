import { RuleLogEntryType } from 'gen/RuleLogEntryType';
import { IStrings } from 'localization/IStrings';

export const createRuleLogEntryTypeRecord = (strings: IStrings): Record<RuleLogEntryType, string> => {
	return {
		Activated: strings.activated,
		Deactivated: strings.deactivated,
		Added: strings.added,
		Changed: strings.changed,
		Deleted: strings.deleted,
	};
};
