import { Form, Formik, FormikActions } from 'formik';
import { IStrings } from 'localization/IStrings';
import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { RouterContext } from '../../context/RouterContext';
import { AuthClient, ILoginRequest, LoginRequest } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import * as routes from '../../routes';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { FormDialog } from '../common/forms/FormDialog';
import { setFieldError } from '../common/forms/FormsUtils';
import { PasswordField } from '../common/forms/PasswordField';
import { TextInputField } from '../common/forms/TextInputField';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';
import './Login.scss';

const createSchema = (strings: IStrings) => {
	return yup.object<ILoginRequest>({
		email: yup.string().email().required(strings.validateUsername),
		password: yup.string().required(strings.validatePassword),
	});
};

export const LoginPage = () => {
	const strings = useLocalizationContext();
	const routerContext = useContext(RouterContext);
	const authContext = useContext(AuthContext);
	const client = useClient<AuthClient>(AuthClient);
	const schema = useMemo(() => createSchema(strings), [strings]);

	const submit = async (props: ILoginRequest, actions: FormikActions<ILoginRequest>) => {
		console.log('enters here');
		const loginResult = await client.login(new LoginRequest(props));
		if (!loginResult.hasError && loginResult.token) {
			authContext.login({ token: loginResult.token!, user: loginResult.user! });
			routerContext.history.push(routes.DashboardRoute);
		} else {
			if (loginResult.errorEmail) {
				setFieldError<ILoginRequest>('email', loginResult.errorEmail, actions);
			}
			if (loginResult.errorPassword) {
				setFieldError<ILoginRequest>('email', loginResult.errorPassword, actions);
			}
		}
		actions.setSubmitting(false);
	};

	const onForgotPassword = (e: any) => {
		routerContext.history.push(routes.ForgotPasswordRoute);
	};

	return (
		<EmptyPageWithLogo
			title={strings.adminLoginWelcomeTitle}
			subTitle={strings.loginWelcomeSubtitle}>
			<FormDialog style={{ width: 600 }}>
				<Formik<ILoginRequest>
					initialValues={{ email: '', password: '' }}
					validationSchema={schema}
					onSubmit={submit}>
					{props => (
						<Form>
							<div>
								<TextInputField<ILoginRequest>
									label={strings.email}
									xName='email'
								/>
								<PasswordField<ILoginRequest>
									label={strings.password}
									xName='password'
									noMargin
								/>
								<TextLinkButton
									gray
									onClick={onForgotPassword}
									style={{ marginTop: 4, marginBottom: 20 }}>
									{strings.forgotPassword}
								</TextLinkButton>
							</div>
							<PrimaryButton
								disabled={props.isSubmitting}
								type='submit'>
								{strings.login}
							</PrimaryButton>
						</Form>
					)}
				</Formik>
			</FormDialog>
		</EmptyPageWithLogo>
	);
};
