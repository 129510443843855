import React from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { DefaultDateFormat } from '../../../constants';
import { toDefaultDate, toDefaultFormat } from '../../../infrastructure/Utils';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';

interface IProps {
    placeholder?: string;
    value: Date | undefined;
    onValueChanged: (date: Date | undefined) => void;
    className?: string;
}

export const DefaultDateInput = ({value, onValueChanged, placeholder, ...rest}: IProps) => {
    const handleChange = (e: any, data: any)  => {
        onValueChanged(toDefaultDate(data.value));
    }
    const handleClear = () => {
        onValueChanged(undefined);
    }

    return (
        <DateInput
            {...rest}
            className={useCombineClassNames('', rest)}
            dateFormat={DefaultDateFormat}
            value={toDefaultFormat(value)}
            onChange={handleChange}
            placeholder={placeholder}
            clearable
            onClear={handleClear}
            animation='fade'
            closable
            iconPosition='left' />
    );
}