import { useRecordContext } from 'context/records/useRecordContext';
import { FunctionalClaim, FunctionalClaims } from 'gen/FunctionalClaim';
import { executeApiCall } from 'infrastructure/executeApiCall';
import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { IEuroSprintersUserDto, UsersCommandClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { showErrorToast } from '../common/toast/ToastNotification';

interface IProps {
	cancel: VoidFunction;
	confirm: VoidFunction;
	user: IEuroSprintersUserDto;
}

export const PatchClaimsForm = ({ cancel, confirm, user }: IProps) => {
	const strings = useLocalizationContext();
	const [selectedClaims, setSelectedClaims] = useState<FunctionalClaim[]>(user.claims as FunctionalClaim[]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { functionalClaimRecord } = useRecordContext();
	const commandClient = useClient(UsersCommandClient);

	const onClickSubmit = async () => {
		setIsSubmitting(true);
		const r = await executeApiCall(commandClient.patchClaims(user.id!, selectedClaims), strings);
		setIsSubmitting(false);
		if (r.hasError === false) {
			confirm();
		} else {
			showErrorToast(r.errorMessage!);
		}
	};

	const onClickClaim = (claim: FunctionalClaim) => {
		if (selectedClaims.indexOf(claim) > -1) {
			setSelectedClaims(selectedClaims.filter(t => t !== claim));
		} else {
			setSelectedClaims([...selectedClaims, claim]);
		}
	};

	return (
		<div
			className='df-col pos-rel'
			style={{ height: '100%' }}>
			<div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>{strings.changeUserRights}</div>
			{FunctionalClaims.map(t => (
				<div
					className='df-row-ac'
					style={{ marginTop: 8, marginBottom: 8 }}>
					<Checkbox
						checked={selectedClaims.indexOf(t) > -1}
						onClick={() => onClickClaim(t)}
					/>
					<div style={{ marginLeft: 8, fontSize: '16px' }}>{functionalClaimRecord[t]}</div>
				</div>
			))}
			<div style={{ flexGrow: 1 }}></div>
			<div className='df-row-ac jc-e'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => cancel()}
						style={{ marginRight: 8 }}>
						{strings.cancel}
					</TextLinkButton>
					<PrimaryButton
						disabled={isSubmitting}
						type='submit'
						onClick={() => onClickSubmit()}>
						{strings.change}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
