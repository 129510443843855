import { RouterContext } from 'context/RouterContext';
import React, { useContext, useEffect } from 'react';
import { NotificationsRoute, RulesRoute } from 'routes';
import { calculateUserHasFunctionalClaim } from './users/calculateUserHasFunctionalClaim';
import { useLoggedInUser } from './users/useLoggedInUser';

export const Dashboard = () => {
	const user = useLoggedInUser();
	const routerContext = useContext(RouterContext);

	useEffect(() => {
		if (user.isSuperUser) {
			routerContext.history.push(NotificationsRoute);
		} else if (calculateUserHasFunctionalClaim(user, 'ViewNotifications')) {
			routerContext.history.push(NotificationsRoute);
		} else if (calculateUserHasFunctionalClaim(user, 'ViewRules')) {
			routerContext.history.push(RulesRoute);
		}
		// eslint-disable-next-line
	}, [user]);

	return <div>{`Welkom ${user.firstName} op admin app van Euro-Sprinters`}</div>;
};
