import React from 'react';
import { empty } from '../infrastructure/Utils';

export interface IRouterProps {
	history: any;
	location: any;
	match: any;
	activeRoute: string;
	navigate: (str: string) => void;
	goBack: () => void;
}

export const RouterContext = React.createContext<IRouterProps>({ history: {}, location: {}, match: {}, activeRoute: '', navigate: empty, goBack: empty });
