import React, { useState, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TextLinkButton } from '../buttons/TextLinkButton';
import { FilterItem } from './FilterItem';
import { CustomListItem } from './CustomListItem';
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import { IOption } from '../../../infrastructure/Models';
import { Searchbox } from '../search/Searchbox';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import _ from 'lodash';
import './ListFilterItem.scss';

interface IProps<T> {
    onFilter: (selection: T[]) => void;
    icon: IconProp;
    label: string;
    options: IOption<T>[];
    selection: T[];
    canSearch?: boolean;
}

export function ListFilterItem<T>({ options, onFilter, icon, label, canSearch = true, selection: initial }: IProps<T>) {
    const locContext = useLocalizationContext();
    const [selection, setSelection] = useState<T[]>(initial ? initial : []); //these must be the values...
    const [filteredOptions, setFilteredOptions] = useState<IOption<T>[]>([]);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [searchText, setSearchtext] = useState<string>('');

    if (isSubmitted && selection !== initial){
        setSelection(initial);
    }

    useEffectAfterMount(() => {
        setFilteredOptions(_.orderBy(options, t => t.text));
    }, 
    // eslint-disable-next-line
    [options]);

    useEffect(() => {
        filter(searchText);
    }, 
    // eslint-disable-next-line
    [searchText]);

    useEffect(() => {
        if (isSubmitted === false){
            filter(searchText);
        }
    }, 
    // eslint-disable-next-line
    [isSubmitted]);

    const filter = (str: string) => {
        const x = options.filter(t => t.text.toLowerCase().includes(str.toLowerCase()));
        setFilteredOptions(_.orderBy(x, t => t.text));
    }

    const handleAll = () => {
        setSelection(options.map(t => t.value));
    }

    const handleNone = () => {
        setSelection([]);
    }

    const handleSearchTextChanged = (str: string) => {
        const x = options.filter(t => t.text.toLowerCase().includes(str.toLowerCase()));
        setFilteredOptions(_.orderBy(x, t => t.text));
    }

    const handleToggle = (value: T) => {
        if (selection.includes(value)) {
            setSelection([...selection].splice(0, selection.indexOf(value)).concat(selection.slice(selection.indexOf(value) + 1)));
        } else {
            setSelection([...selection, value]);
        }
    }

    const onClickAway = () => {
        if (selection !== initial){
            onFilter(selection);
        }
        setIsSubmitted(true);
    }

    return (
        <FilterItem
            icon={icon}
            count={selection.length}
            label={label}
            onClickAway={onClickAway}
            onOpened={() => setIsSubmitted(false)}>
            <div className="df-col dd-sticky-header">
                {canSearch ?
                    // <CustomSearchBox placeholder="Vind status..." onTextChanged={handleSearchTextChanged} />
                    <Searchbox className="filter-search-box" placeholder={`Vind ${label}...`} externalProps={{text: searchText, setText: setSearchtext}} onSearch={handleSearchTextChanged} />
                    : null}
                <div className="df-row-ac jc-sb dd-header">
                    <div className="dd-header-title">{locContext.filterOn} {label}</div>
                    <div className="df-row-ac">
                        <TextLinkButton className="dd-all-button" onClick={handleAll}>{locContext.all.toUpperCase()}</TextLinkButton>
                        <TextLinkButton className="dd-none-button" onClick={handleNone}>{locContext.none.toUpperCase()}</TextLinkButton>
                    </div>
                </div>
            </div>
            <div className="df-col dd-items">
                {filteredOptions.map(t => <CustomListItem key={t.key}
                    checked={selection.includes(t.value)}
                    label={t.text}
                    onToggle={() => handleToggle(t.value)} />)}
            </div>
        </FilterItem>
    );
}