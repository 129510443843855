import { IPageProps, ISortProps } from "../infrastructure/Models";

// export function useTableSortAndPage<T extends ISortAndPageProps>(initialValue: T, fallBackSortProps?: ISortProps): [T, (inst: T) => void, (columnn: string) => void, () => void, () => void] {
//     const [val, setVal] = useState<T>(initialValue);
//     return [val, setVal, (column: string) => {
//         // edge-case ico fallbackValue set.
//         if (fallBackSortProps && fallBackSortProps.sortProperty == column) {
//             setVal({ ...val, sortProperty: column, sortDirection: val.sortDirection && val.sortDirection === 'ascending' ? 'descending' : 'ascending', pageIndex: 1 })
//         } else if (val.sortProperty !== column) {
//             setVal({ ...val, sortProperty: column, sortDirection: 'ascending', pageIndex: 1 });
//         } else if (val.sortDirection === 'ascending') {
//             setVal({ ...val, sortProperty: column, sortDirection: 'descending', pageIndex: 1 });
//         } else {
//             setVal(fallBackSortProps ? { ...val, ...fallBackSortProps, pageIndex: 1 } : { ...val, sortProperty: '', sortDirection: undefined, pageIndex: 1 });
//         }
//     }, () => setVal({ ...val, pageIndex: val.pageIndex ? val.pageIndex + 1 : 1 }), () => setVal({ ...val, pageIndex: val.pageIndex ? val.pageIndex - 1 : 1 })]
// }

// (column: string, inst: T) => T , (inst: T) => T, (inst: T) => T
export function useTablePage<T extends IPageProps>(): [(inst: T) => T, (inst: T) => T] {
    // sort, next, previous
    return [(inst: T) => ({ ...inst, pageIndex: inst.pageIndex ? inst.pageIndex + 1 : 1 }),
    (inst: T) => ({ ...inst, pageIndex: inst.pageIndex ? inst.pageIndex - 1 : 1 })]
}
export function useTableSort<T extends ISortProps>(fallBackSortProps?: ISortProps): ((inst: T, column: string) => T) {
    return (inst: T, column: string) => {
        // edge-case ico fallbackValue set.
        if (fallBackSortProps && fallBackSortProps.sortProperty === column) {
            return ({ ...inst, sortProperty: column, sortDirection: inst.sortDirection && inst.sortDirection === 'ascending' ? 'descending' : 'ascending', pageIndex: 1 });
        } else if (inst.sortProperty !== column) {
            return ({ ...inst, sortProperty: column, sortDirection: 'ascending', pageIndex: 1 });
        } else if (inst.sortDirection === 'ascending') {
            return ({ ...inst, sortProperty: column, sortDirection: 'descending', pageIndex: 1 });
        } else {
            return(fallBackSortProps ? { ...inst, ...fallBackSortProps, pageIndex: 1 } : { ...inst, sortProperty: '', sortDirection: undefined, pageIndex: 1 });
        }
    }
}