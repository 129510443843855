import { IRulesLogEntriesQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { FilterBar } from '../common/filter/FilterBar';
import React from 'react';

function isFiltered(pars: IRulesLogEntriesQueryParams): boolean {
	if (pars === undefined) {
		return false;
	} else if (pars.timeStampAfter !== undefined) {
		return true;
	} else if (pars.timeStampBefore !== undefined) {
		return true;
	} else if ((pars.byUserIds?.length ?? 0) > 0) {
		return true;
	} else {
		return false;
	}
}

interface IProps {
	params: IRulesLogEntriesQueryParams;
	onFilter: (params: IRulesLogEntriesQueryParams) => void;
}

export const FilterRuleLogs = ({ params, onFilter }: IProps) => {
	const locContext = useLocalizationContext();

	const handleClear = () => {
		onFilter({
			...params,
			byUserIds: [],
			timeStampAfter: undefined,
			timeStampBefore: undefined,
		});
	};

	return (
		<FilterBar
			onClear={() => handleClear()}
			isFiltered={isFiltered(params)}>
			<div className='df-row-ac'>
				<DateFilterItem
					undefinedLabel={locContext.date}
					afterDate={params.timeStampAfter}
					beforeDate={params.timeStampBefore}
					onFilter={val => onFilter({ ...params, timeStampAfter: val.after, timeStampBefore: val.before })}
				/>
			</div>
		</FilterBar>
	);
};
