import React from 'react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import './BaseInformation.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    title: string | undefined;
    description: string | JSX.Element | undefined;
}
export const BaseInformation = ({title, description, ...rest}: IProps) => {
    return (
        <div className={useCombineClassNames('df-col stretch-ver information-modal', rest)}>
            <div className="mc-title">
                {title}
            </div>
            <div className="mc-description">
                {description}
            </div>
        </div>
    );
}